<template>
  <NoteSection
    label="Examination of Body Areas"
  >
    <help-icon
      title="DFU Risk Review"
      body="<p>It is recommended that ALL patients at risk for skin breakdown or wound receive a
            weekly comprehensive skin examination of their entire body by their wound physician
            to identify if there are any new skin lesions or at risk areas. The areas of the
            body which are routinely examined are listed below. Mark all that apply.</p>"
    ></help-icon>

    <check-boxes
      v-model="body_areas"
      :items="bodyAreas"
      class="col-sm-12 mt--1"
      columns="3"
      column-sort
      hide-details
      required
      return-object
    ></check-boxes>

    <v-card-actions
      v-if="!isSigned"
      class="col-sm-12"
    >
      <!-- clear -->
      <btn
        label="Clear All"
        color="secondary"
        :icon="icons.mdiCheckboxMultipleBlankOutline"
        @click="examinationAreasClear"
      ></btn>

      <v-spacer></v-spacer>

      <!-- select all -->
      <btn
        label="Select All"
        :icon="icons.mdiCheckboxMultipleMarked"
        @click="examinationAreasSelectAll"
      ></btn>
    </v-card-actions>

    <text-area
      v-model="body_areas_notes"
      label="Enter additional examination notes..."
      class="col-sm-12"
      counter="2500"
      maxlength="2500"
    ></text-area>
  </NoteSection>
</template>

<script>
import { mapGetters } from 'vuex'
import { mdiCheckboxMultipleBlankOutline, mdiCheckboxMultipleMarked } from '@mdi/js'

export default {
  props: {
    value: {
      type: Array,
      default: null,
    },
    bodyAreasNotes: {
      type: String,
      default: null,
    },
    isSigned: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      body_areas: this.value,
      body_areas_notes: this.bodyAreasNotes,
      icons: { mdiCheckboxMultipleBlankOutline, mdiCheckboxMultipleMarked },
    }
  },
  computed: {
    ...mapGetters('encounters', ['bodyAreas']),
  },
  watch: {
    body_areas() {
      this.$emit('input', this.body_areas)
    },
    body_areas_notes() {
      this.$emit('update:body-areas-notes', this.body_areas_notes)
    },
  },
  methods: {
    examinationAreasClear() {
      this.$root.confirm({
        title: 'Clear All Examination Areas?',
        body: 'Are you sure you wish to clear all examination areas?',
        cancel: 'No',
        confirm: 'Yes',
      }).then(result => {
        if (result) {
          this.body_areas = []
        }
      })
    },
    examinationAreasSelectAll() {
      this.$root.confirm({
        title: 'Select All Examination Areas?',
        body: 'Are you sure you wish to select all examination areas?',
        cancel: 'No',
        confirm: 'Yes',
      }).then(result => {
        if (result) {
          this.body_areas = []
          Object.keys(this.bodyAreas).forEach(i => this.body_areas.push(this.bodyAreas[i]))
        }
      })
    },
  },
}
</script>
