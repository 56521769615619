<template>
  <NoteSection
    label="Recent Hospitalization"
  >
    <help-icon
      title="Recent Hospitalization"
      body="<p>The patient had a recent hospitalization and given that the patient was
      transported in and out of the facility and was hospitalized, the patient's wound
      may or may not have declined while at the hospital or during transport.</p>"
    ></help-icon>
    <radio-buttons
      v-model="section.recently_hospitalized"
      :items="query"
      label="Has the patient been recently hospitalized?"
      class="col-sm-12"
      required
      row
      @input="hospitalCheck"
    ></radio-buttons>
    <checkbox
      v-if="section.recently_hospitalized"
      v-model="section.recently_hospitalized_related"
      label="Recent hospital visit was wound related"
      class="col-sm-7"
      row
    ></checkbox>
    <date-picker
      v-if="section.recently_hospitalized"
      v-model="section.recently_hospitalized_date"
      :max="$date().format('YYYY-MM-DD')"
      :required="isInvalidDate"
      label="Date Hospitalized"
      class="col-sm-5"
      clearable
      @input="visitDateCheck"
    ></date-picker>
  </NoteSection>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    recentlyHospitalized: {
      type: Boolean,
      default: null,
    },
    recentlyHospitalizedRelated: {
      type: Boolean,
      default: null,
    },
    recentlyHospitalizedDate: {
      type: String,
      default: null,
    },
    facilityId: {
      type: Number,
      default: -1,
    },
    encounterVisitDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      section: {
        recently_hospitalized: this.recentlyHospitalized,
        recently_hospitalized_related: this.recentlyHospitalizedRelated,
        recently_hospitalized_date: this.recentlyHospitalizedDate,
        facility_id: this.facilityId,
      },
      encounter_visit_date: this.encounterVisitDate,
      hospital_dialog_ack: 0,
      isInvalidDate: false,
    }
  },
  computed: {
    ...mapGetters('baseData', ['query']),
  },
  watch: {
    'section.recently_hospitalized_date': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && newValue) this.dateSpanCheck(newValue)
      },
    },
    section: {
      deep: true,
      handler() {
        this.$emit('update:recently-hospitalized', this.section.recently_hospitalized)
        this.$emit('update:recently-hospitalized-related', this.section.recently_hospitalized_related)
        this.$emit('update:recently-hospitalized-date', this.section.recently_hospitalized_date)
      },
      immediate: true,
    },
    recentlyHospitalized() {
      if (this.section.recently_hospitalized === false) {
        this.section.recently_hospitalized_related = false
        this.section.recently_hospitalized_date = null
      }
    },
  },
  mounted() {
    // method from encounter details to update variable when date changes
    this.$root.$on('dateChange', date => {
      this.encounter_visit_date = date
      if (this.section.recently_hospitalized_date) this.visitDateCheck()
    })
  },
  methods: {
    // method shows dialog if user has been recently hospitalized
    hospitalCheck($event) {
      if ($event && this.hospital_dialog_ack === 0) {
        const facilityName = this.$store.getters['facilities/getById'](this.facilityId).title.toUpperCase()
        this.hospital_dialog_ack = 1
        this.$root.confirm({
          title: 'Notice!',
          subTitle: 'Please verify that the patient is still in the following facility: ',
          body: facilityName,
          large: true,
          cancel: false,
        })
      }
    },

    // shows dialog if visit date occurs before selected recent hospitalization date
    visitDateCheck() {
      if (this.encounter_visit_date < this.section.recently_hospitalized_date) {
        this.isInvalidDate = true
        this.$root.confirm({
          title: 'Notice: Date of Service',
          titleIconColor: 'warning',
          body: 'Patient hospitalization date cannot occur after the note date of service',
          cancel: false,
        })
        this.section.recently_hospitalized_date = undefined
      } else {
        this.isInvalidDate = false
      }
    },
    dateSpanCheck(date) {
      // if visit is greater than 60 days notify
      const visitDate = new Date(date)
      visitDate.setDate(visitDate.getDate() + 60)
      const [finalDate] = visitDate.toISOString().split('T')

      if (finalDate < this.encounter_visit_date) this.$store.dispatch('notify', { value: 'Dates spanning 8 or more weeks from the DOS may be invalid', color: 'warning' })
    },
  },
}
</script>
