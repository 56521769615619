<template>
  <NoteSection
    label="Labs"
  >
    <select-box
      v-model="labs"
      :items="labOptions"
      label="Choose Labs"
      class="col-sm-7"
      multiple
      return-object
      @input="$emit('input', labs)"
    ></select-box>
    <date-picker
      v-model="labs_order_date"
      :max="$date().format('YYYY-MM-DD')"
      label="Date Ordered"
      class="col-sm-5 align-self-start"
      clearable
      :required="labs.length > 0"
      @input="$emit('update:labs-order-date', labs_order_date)"
    ></date-picker>
    <text-area
      v-if="labs.some(x => x.title === 'Other')"
      v-model="labs_other"
      class="col-sm-12"
      label="Enter other labs..."
      required
      counter="7300"
      maxlength="7300"
      @input="$emit('update:labs-other', labs_other)"
    ></text-area>
  </NoteSection>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Array,
      default: null,
    },
    labsOther: {
      type: String,
      default: null,
    },
    labsOrderDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      labs: [...this.value],
      labs_other: this.labsOther,
      labs_order_date: this.labsOrderDate,
    }
  },
  computed: {
    ...mapGetters('encounters', ['labOptions']),
  },
  watch: {
  },
  methods: {
  },
}
</script>
