<template>
  <NoteSection
    label="Vitals"
  >
    <help-icon
      title="Vitals"
      body="<p>The information in this section may always be supplied, but is only required when the
      patient is indicated as being on antibiotics in the Assessment Plan below, or when any
      treated wound has a 'sign of infection' indicated.</p>"
    ></help-icon>
    <text-field
      ref="temp"
      v-model="vitals.temperature"
      :required="requireVitals"
      class="col-sm-6"
      label="Temperature"
      numeric
      :mask="tempMask"
      :rules="(vitals.temperature || requireVitals) && dynamicTemperatureChange()"
      @input="temperatureChanged"
    ></text-field>
    <v-spacer class="col-sm-1"></v-spacer>
    <radio-buttons
      v-model="vitals.temperature_scale"
      :items="temperatureScaleOptions"
      :required="(requireVitals || !!vitals.temperature)"
      clearable
      class="col-sm-5"
    ></radio-buttons>
    <text-field
      v-model="vitals.heart_rate"
      :required="requireVitals"
      class="col-sm-4 pt-5"
      label="Heart Rate"
      placeholder="bpm"
      numeric
      :rules="(vitals.heart_rate|| requireVitals) && heartRateRules"
      :mask="tempMask"
    ></text-field>
    <text-field
      v-model="vitals.respiratory_rate"
      :required="requireVitals"
      class="col-sm-4 pt-5"
      label="Respiratory Rate"
      placeholder="cpm"
      numeric
      :rules="(vitals.respiratory_rate|| requireVitals) && respiratoryRateRules"
      mask="###"
    ></text-field>
    <v-col
      cols="4"
      class="p-0"
    >
      <v-col
        cols="12"
        class="pa-0"
      >
        <div
          class="text-h7 col-sm-12 font-weight-black pa-0"
        >
          Blood Pressure
        </div>
      </v-col>
      <v-col
        cols="12"
        class="d-flex justify-left pa-0"
      >
        <text-field
          v-model="vitals.bp_systolic"
          :required="requireVitals"
          class="col-sm-5"
          label="Systolic"
          placeholder="mmHg"
          numeric
          :mask="tempMask"
          :rules="(vitals.bp_systolic || requireVitals) && systolicRules"
        ></text-field>
        <div
          class="my-2"
        >
          <v-icon>
            {{ icon.mdiSlashForward }}
          </v-icon>
        </div>
        <text-field
          v-model="vitals.bp_diastolic"
          :required="requireVitals"
          class="col-sm-5"
          label="Diastolic"
          placeholder="mmHg"
          numeric
          :mask="tempMask"
          :rules="(vitals.bp_diastolic || requireVitals) && diastolicRules"
        ></text-field>
      </v-col>
    </v-col>
  </NoteSection>
</template>

<script>
import { mdiSlashForward } from '@mdi/js'

export default {
  props: {
    temperature: {
      type: String,
      default: null,
    },
    temperatureScale: {
      type: String,
      default: null,
    },
    heartRate: {
      type: String,
      default: null,
    },
    bpSystolic: {
      type: String,
      default: null,
    },
    bpDiastolic: {
      type: String,
      default: null,
    },
    respiratoryRate: {
      type: String,
      default: null,
    },
    requireVitals: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      icon: {
        mdiSlashForward,
      },

      // Rules
      rules: {
        bloodPressure: this.requireVitals ? 'bloodPressureRequired' : 'bloodPressure',
      },

      // Ranges
      rangeCelsius: {
        min: 11,
        max: 47,
      },
      rangeFahrenheit: {
        min: 53,
        max: 115,
      },
      rangeHeartRate: {
        min: 20,
        max: 250,
      },
      rangeBpDiastolic: {
        min: 20,
        max: 250,
      },
      rangeBpSystolic: {
        min: 20,
        max: 250,
      },
      rangeRespiratoryRate: {
        min: 1,
        max: 60,
      },

      vitals: {
        temperature: this.temperature,
        temperature_scale: this.temperatureScale,
        heart_rate: this.heartRate,
        bp_systolic: this.bpSystolic,
        bp_diastolic: this.bpDiastolic,
        respiratory_rate: this.respiratoryRate,
      },
      temperatureScaleOptions: [
        { title: 'Celsius', value: 'C' },
        { title: 'Fahrenheit', value: 'F' },
      ],
      tempMask: [/[0-9]/, /[0-9]/, /[0-9|.]/, /[0-9|.]/, /[0-9]/],
    }
  },
  computed: {
    watchedTempScale() {
      return this.vitals.temperature_scale
    },

    // Rules
    heartRateRules() { return `requiredNumberRange(${this.rangeHeartRate.min}, ${this.rangeHeartRate.max})` },
    respiratoryRateRules() { return `requiredNumberRange(${this.rangeRespiratoryRate.min}, ${this.rangeRespiratoryRate.max})` },
    diastolicRules() { return `requiredNumberRange(${this.rangeBpDiastolic.min}, ${this.rangeBpDiastolic.max})` },
    systolicRules() { return `requiredNumberRange(${this.rangeBpSystolic.min},${this.rangeBpSystolic.max})` },
  },
  watch: {
    vitals: {
      deep: true,
      handler() {
        this.$emit('update:temperature', this.vitals.temperature)
        this.$emit('update:temperature-scale', this.vitals.temperature_scale)
        this.$emit('update:heart-rate', this.vitals.heart_rate)
        this.$emit('update:bp-systolic', this.vitals.bp_systolic)
        this.$emit('update:bp-diastolic', this.vitals.bp_diastolic)
        this.$emit('update:respiratory-rate', this.vitals.respiratory_rate)
      },
    },
    watchedTempScale() {
      this.$nextTick(() => {
        this.$refs.temp.validate()
      })
    },
  },
  methods: {
    temperatureChanged() {
      if (this.vitals.temperature >= this.rangeCelsius.min
      && this.vitals.temperature <= this.rangeCelsius.max) {
        this.vitals.temperature_scale = 'C'
      } else if (this.vitals.temperature >= this.rangeFahrenheit.min
      && this.vitals.temperature <= this.rangeFahrenheit.max) this.vitals.temperature_scale = 'F'
      else this.vitals.temperature_scale = null
    },
    dynamicTemperatureChange() {
      if (this.vitals.temperature_scale === 'C') {
        return `requiredNumberRange(${this.rangeCelsius.min}, ${this.rangeCelsius.max})`
      }
      if (this.vitals.temperature_scale === 'F') {
        return `requiredNumberRange(${this.rangeFahrenheit.min}, ${this.rangeFahrenheit.max})`
      }

      return `requiredNumberRange(${this.rangeCelsius.min}, ${this.rangeFahrenheit.max})`
    },
  },
}
</script>
