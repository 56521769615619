<template>
  <NoteSection
    label="Social History"
  >
    <radio-buttons
      v-model="social_history_contributory"
      :items="query"
      label="Contributory?"
      class="col-sm-12 mt--1"
      hide-details
      required
      row
    ></radio-buttons>
    <check-boxes
      v-model="social_histories"
      :items="socialHistoryOptions"
      class="col-sm-12"
      columns="3"
      column-sort
      hide-details
      return-object
      :required="isSocialHistoryRequired"
    ></check-boxes>
    <text-area
      v-model="social_history_other"
      class="col-sm-12"
      label="Enter other social history..."
      :required="isSocialHistoryRequired"
      counter="900"
      maxlength="900"
    ></text-area>
  </NoteSection>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    socialHistoryContributory: {
      type: [Boolean, Number],
      default: null,
    },
    socialHistories: {
      type: Array,
      default: null,
    },
    socialHistoryOther: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      social_history_contributory: this.socialHistoryContributory,
      social_histories: [...this.socialHistories],
      social_history_other: this.socialHistoryOther,
    }
  },
  computed: {
    ...mapGetters('baseData', ['query']),
    ...mapGetters('encounters', ['socialHistoryOptions']),
    isSocialHistoryRequired() {
      return this.social_history_contributory
        && this.social_histories.length === 0
        && !this.social_history_other
    },
  },
  watch: {
    social_history_contributory(newValue) {
      this.$emit('update:social-history-contributory', this.social_history_contributory)

      // Clear 'other' text if user changed to non-contributory.
      if (!newValue) {
        this.social_history_other = null
      }
    },
    social_histories() {
      this.$emit('update:social-histories', this.social_histories)
    },
    social_history_other() {
      this.$emit('update:social-history-other', this.social_history_other)
    },
  },
  methods: {
  },
}
</script>
