<template>
  <NoteSection>
    <v-tabs
      v-model="activeTab"
      fixed-tabs
      color="success"
      class="col-sm-12 mb-5"
    >
      <v-tab>Undermining</v-tab>
      <v-tab>Tunneling</v-tab>
      <v-tab>Sinus Tract</v-tab>

      <!-- undermining -->
      <v-tab-item eager>
        <div class="wound-wheel">
          <div class="o-clock clock12"></div>
          <div class="o-clock clock3"></div>
          <div class="o-clock clock6"></div>
          <div class="o-clock clock9"></div>

          <div class="dividerVertical"></div>
          <div class="dividerHorizontal"></div>

          <div class="quadrants quad-1">
            <text-field
              v-model="undermining.inside_quadrant_1"
              :mask="measurementMask"
              :disabled="!!undermining.outside_12_oclock
                      || !!undermining.outside_1_oclock
                      || !!undermining.outside_2_oclock
                      || !!undermining.outside_3_oclock"
              numeric
              rules="numberRange(0,100)"
              class="quadrant-1"
            ></text-field>
          </div>
          <div class="quadrants quad-2">
            <text-field
              v-model="undermining.inside_quadrant_2"
              :mask="measurementMask"
              :disabled="!!undermining.outside_3_oclock
                      || !!undermining.outside_4_oclock
                      || !!undermining.outside_5_oclock
                      || !!undermining.outside_6_oclock"
              numeric
              rules="numberRange(0,100)"
              class="quadrant-2"
            ></text-field>
          </div>
          <div class="quadrants quad-3">
            <text-field
              v-model="undermining.inside_quadrant_3"
              :mask="measurementMask"
              :disabled="!!undermining.outside_6_oclock
                      || !!undermining.outside_7_oclock
                      || !!undermining.outside_8_oclock
                      || !!undermining.outside_9_oclock"
              numeric
              rules="numberRange(0,100)"
              class="quadrant-3"
            ></text-field>
          </div>
          <div class="quadrants quad-4">
            <text-field
              v-model="undermining.inside_quadrant_4"
              :mask="measurementMask"
              :disabled="!!undermining.outside_9_oclock
                      || !!undermining.outside_10_oclock
                      || !!undermining.outside_11_oclock
                      || !!undermining.outside_12_oclock"
              numeric
              rules="numberRange(0,100)"
              class="quadrant-4"
            ></text-field>
          </div>

          <text-field
            v-model="undermining.outside_1_oclock"
            :mask="measurementMask"
            :disabled="!!undermining.inside_quadrant_1"
            numeric
            rules="numberRange(0,100)"
            class="oclock-1"
          ></text-field>
          <text-field
            v-model="undermining.outside_2_oclock"
            :mask="measurementMask"
            :disabled="!!undermining.inside_quadrant_1"
            numeric
            rules="numberRange(0,100)"
            class="oclock-2"
          ></text-field>
          <text-field
            v-model="undermining.outside_3_oclock"
            :mask="measurementMask"
            :disabled="!!undermining.inside_quadrant_1 || !!undermining.inside_quadrant_2"
            numeric
            rules="numberRange(0,100)"
            class="oclock-3"
          ></text-field>
          <text-field
            v-model="undermining.outside_4_oclock"
            :mask="measurementMask"
            :disabled="!!undermining.inside_quadrant_2"
            numeric
            rules="numberRange(0,100)"
            class="oclock-4"
          ></text-field>
          <text-field
            v-model="undermining.outside_5_oclock"
            :mask="measurementMask"
            :disabled="!!undermining.inside_quadrant_2"
            numeric
            rules="numberRange(0,100)"
            class="oclock-5"
          ></text-field>
          <text-field
            v-model="undermining.outside_6_oclock"
            :mask="measurementMask"
            :disabled="!!undermining.inside_quadrant_2 || !!undermining.inside_quadrant_3"
            numeric
            rules="numberRange(0,100)"
            class="oclock-6"
          ></text-field>
          <text-field
            v-model="undermining.outside_7_oclock"
            :mask="measurementMask"
            :disabled="!!undermining.inside_quadrant_3"
            numeric
            rules="numberRange(0,100)"
            class="oclock-7"
          ></text-field>
          <text-field
            v-model="undermining.outside_8_oclock"
            :mask="measurementMask"
            :disabled="!!undermining.inside_quadrant_3"
            numeric
            rules="numberRange(0,100)"
            class="oclock-8"
          ></text-field>
          <text-field
            v-model="undermining.outside_9_oclock"
            :mask="measurementMask"
            :disabled="!!undermining.inside_quadrant_3 || !!undermining.inside_quadrant_4"
            numeric
            rules="numberRange(0,100)"
            class="oclock-9"
          ></text-field>
          <text-field
            v-model="undermining.outside_10_oclock"
            :mask="measurementMask"
            :disabled="!!undermining.inside_quadrant_4"
            numeric
            rules="numberRange(0,100)"
            class="oclock-10"
          ></text-field>
          <text-field
            v-model="undermining.outside_11_oclock"
            :mask="measurementMask"
            :disabled="!!undermining.inside_quadrant_4"
            numeric
            rules="numberRange(0,100)"
            class="oclock-11"
          ></text-field>
          <text-field
            v-model="undermining.outside_12_oclock"
            :mask="measurementMask"
            :disabled="!!undermining.inside_quadrant_1 || !!undermining.inside_quadrant_4"
            numeric
            rules="numberRange(0,100)"
            class="oclock-12"
          ></text-field>
        </div>

        <btn
          v-if="!isSigned"
          label="Clear All"
          :icon="icons.mdiCheckboxMultipleBlankOutline"
          color="secondary"
          class="clear-all-wheel"
          @click="clearWheel('Undermining')"
        ></btn>
      </v-tab-item>

      <!-- tunneling -->
      <v-tab-item eager>
        <div class="wound-wheel">
          <div class="o-clock clock12"></div>
          <div class="o-clock clock3"></div>
          <div class="o-clock clock6"></div>
          <div class="o-clock clock9"></div>

          <div class="dividerVertical"></div>
          <div class="dividerHorizontal"></div>

          <text-field
            v-model="tunneling.outside_1_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-1"
          ></text-field>
          <text-field
            v-model="tunneling.outside_2_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-2"
          ></text-field>
          <text-field
            v-model="tunneling.outside_3_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-3"
          ></text-field>
          <text-field
            v-model="tunneling.outside_4_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-4"
          ></text-field>
          <text-field
            v-model="tunneling.outside_5_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-5"
          ></text-field>
          <text-field
            v-model="tunneling.outside_6_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-6"
          ></text-field>
          <text-field
            v-model="tunneling.outside_7_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-7"
          ></text-field>
          <text-field
            v-model="tunneling.outside_8_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-8"
          ></text-field>
          <text-field
            v-model="tunneling.outside_9_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-9"
          ></text-field>
          <text-field
            v-model="tunneling.outside_10_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-10"
          ></text-field>
          <text-field
            v-model="tunneling.outside_11_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-11"
          ></text-field>
          <text-field
            v-model="tunneling.outside_12_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-12"
          ></text-field>
        </div>

        <btn
          v-if="!isSigned"
          label="Clear All"
          :icon="icons.mdiCheckboxMultipleBlankOutline"
          color="secondary"
          class="clear-all-wheel"
          @click="clearWheel('Tunneling')"
        ></btn>
      </v-tab-item>

      <!-- sinus tract -->
      <v-tab-item eager>
        <div class="wound-wheel">
          <div class="o-clock clock12"></div>
          <div class="o-clock clock3"></div>
          <div class="o-clock clock6"></div>
          <div class="o-clock clock9"></div>

          <div class="dividerVertical"></div>
          <div class="dividerHorizontal"></div>

          <text-field
            v-model="sinus_tract.outside_1_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-1"
          ></text-field>
          <text-field
            v-model="sinus_tract.outside_2_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-2"
          ></text-field>
          <text-field
            v-model="sinus_tract.outside_3_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-3"
          ></text-field>
          <text-field
            v-model="sinus_tract.outside_4_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-4"
          ></text-field>
          <text-field
            v-model="sinus_tract.outside_5_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-5"
          ></text-field>
          <text-field
            v-model="sinus_tract.outside_6_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-6"
          ></text-field>
          <text-field
            v-model="sinus_tract.outside_7_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-7"
          ></text-field>
          <text-field
            v-model="sinus_tract.outside_8_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-8"
          ></text-field>
          <text-field
            v-model="sinus_tract.outside_9_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-9"
          ></text-field>
          <text-field
            v-model="sinus_tract.outside_10_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-10"
          ></text-field>
          <text-field
            v-model="sinus_tract.outside_11_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-11"
          ></text-field>
          <text-field
            v-model="sinus_tract.outside_12_oclock"
            :mask="measurementMask"
            numeric
            rules="numberRange(0,100)"
            class="oclock-12"
          ></text-field>
        </div>

        <btn
          v-if="!isSigned"
          label="Clear All"
          :icon="icons.mdiCheckboxMultipleBlankOutline"
          color="secondary"
          class="clear-all-wheel"
          @click="clearWheel('Sinus Tract')"
        ></btn>
      </v-tab-item>
    </v-tabs>
  </NoteSection>
</template>

<script>
import { mdiCheckboxMultipleBlankOutline } from '@mdi/js'

const inside = {
  inside_quadrant_1: null,
  inside_quadrant_2: null,
  inside_quadrant_3: null,
  inside_quadrant_4: null,
}
const outside = {
  outside_1_oclock: null,
  outside_2_oclock: null,
  outside_3_oclock: null,
  outside_4_oclock: null,
  outside_5_oclock: null,
  outside_6_oclock: null,
  outside_7_oclock: null,
  outside_8_oclock: null,
  outside_9_oclock: null,
  outside_10_oclock: null,
  outside_11_oclock: null,
  outside_12_oclock: null,
}

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    tunnelingWheel: {
      type: Object,
      default: null,
    },
    sinusTractWheel: {
      type: Object,
      default: null,
    },
    isSigned: {
      type: [Boolean, Number],
      default: null,
    },
  },
  data() {
    return {
      activeTab: 0,
      undermining: { ...this.value },
      tunneling: { ...this.tunnelingWheel },
      sinus_tract: { ...this.sinusTractWheel },
      icons: { mdiCheckboxMultipleBlankOutline },
      measurementMask: [/[0-9]/, /[0-9|.]/, /[0-9|.]/, /[0-9]/],
    }
  },
  computed: {
  },
  watch: {
    /* No idea why this was added, doesn't appear to be required
    value: {
      deep: true,
      handler() {
        this.undermining = this.value
        this.activeTab = 0
      },
    },
    */
    tunnelingWheel: {
      deep: true,
      handler() {
        this.tunneling = this.tunnelingWheel
      },
    },
    sinusTractWheel: {
      deep: true,
      handler() {
        this.sinus_tract = this.sinusTractWheel
      },
    },
    undermining: {
      deep: true,
      handler() {
        this.$emit('input', this.undermining)
      },
    },
    tunneling: {
      deep: true,
      handler() {
        this.$emit('update:tunneling-wheel', this.tunneling)
      },
    },
    sinus_tract: {
      deep: true,
      handler() {
        this.$emit('update:sinus-tract-wheel', this.sinus_tract)
      },
    },
  },
  methods: {
    clearWheel(tab) {
      this.$root.confirm({
        title: `Clear All ${tab} Values?`,
        body: 'Are you sure you wish to clear all values?',
        cancel: 'No',
        confirm: 'Yes',
      }).then(result => {
        if (result) {
          switch (tab) {
            case 'Undermining':
              this.undermining = {}
              this.$nextTick(() => {
                this.undermining = { ...inside, ...outside }
              })
              break
            case 'Tunneling':
              this.tunneling = {}
              this.$nextTick(() => {
                this.tunneling = { ...outside }
              })
              break
            default:
              this.sinus_tract = {}
              this.$nextTick(() => {
                this.sinus_tract = { ...outside }
              })
          }
        }
      })
    },
  },
}
</script>
