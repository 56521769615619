<template>
  <NoteSection>
    <text-area
      v-model="section.currentTreatment"
      label="Current Treatment"
      class="col-sm-6"
      counter="false"
      rows="5"
      :paste="isFollowUpTreatment"
      required
      @click:prepend="getPreviousTreatment"
    ></text-area>
    <text-area
      v-model="section.qent_notes"
      label="Notes"
      class="col-sm-6"
      counter="false"
      rows="5"
      :paste="isFollowUpTreatment"
      required
      @click:prepend="getPreviousRecommendation"
    ></text-area>
    <text-area
      v-model="section.operativeNote"
      label="Operative Note"
      class="col-sm-12"
      counter="false"
      rows="5"
      :paste="isFollowUpTreatment"
      @click:prepend="getPreviousOperativeNote"
    ></text-area>
  </NoteSection>
</template>

<script>
export default {
  props: {
    currentTreatment: {
      type: String,
      default: null,
    },
    qent_notes: {
      type: String,
      default: null,
    },
    operativeNote: {
      type: String,
      default: null,
    },
    treatments: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      section: {
        currentTreatment: this.currentTreatment,
        qent_notes: this.qent_notes,
        operativeNote: this.operativeNote,
      },
      wound_treatments: this.treatments.filter(x => x.deleted !== true).sort((a, b) => (a.encounter.visit_date < b.encounter.visit_date && 1) || -1),
    }
  },
  computed: {
    isFollowUpTreatment() {
      return this.wound_treatments ? this.wound_treatments.length > 1 : false
    },
  },
  watch: {
    section: {
      deep: true,
      handler() {
        this.$emit('update:current-treatment', this.section.currentTreatment)
        this.$emit('update:qent_notes', this.section.qent_notes)
        this.$emit('update:operative-note', this.section.operativeNote)
      },
    },
  },
  mounted() {},
  methods: {
    getPreviousTreatment() {
      let temp = ''
      if (this.isFollowUpTreatment) {
        temp = (this.wound_treatments[1].current_treatment || '').trim()
      }
      if (!this.$custom.isEmpty(temp)) {
        this.section.currentTreatment = temp
      } else {
        this.$store.dispatch('notify', { value: 'No previous treatment note to paste.', color: 'warning' })
      }
    },
    getPreviousRecommendation() {
      let temp = ''
      if (this.isFollowUpTreatment) {
        temp = (this.wound_treatments[1].qent_notes || '').trim()
      }
      if (!this.$custom.isEmpty(temp)) {
        this.section.qent_notes = temp
      } else {
        this.$store.dispatch('notify', { value: 'No previous notes to paste.', color: 'warning' })
      }
    },
    getPreviousOperativeNote() {
      let temp = ''
      if (this.isFollowUpTreatment) {
        temp = (this.wound_treatments[1].operative_note || '').trim()
      }
      if (!this.$custom.isEmpty(temp)) {
        this.section.operativeNote = temp
      } else {
        this.$store.dispatch('notify', { value: 'No previous operative note to paste.', color: 'warning' })
      }
    },
  },
}
</script>
