<template>
  <NoteSection
    label="Allergies &amp; Sensitivities"
  >
    <text-area
      v-model="allergies_other"
      label="Allergies"
      class="col-sm-6"
    ></text-area>
    <text-area
      v-model="sensitivities_other"
      label="Sensitivities"
      class="col-sm-6"
    ></text-area>
  </NoteSection>
</template>

<script>
export default {
  props: {
    allergiesOther: {
      type: String,
      default: null,
    },
    sensitivitiesOther: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      allergies_other: this.allergiesOther,
      sensitivities_other: this.sensitivitiesOther,
    }
  },
  computed: {
  },
  watch: {
    allergies_other() {
      this.$emit('update:allergies-other', this.allergies_other)
    },
    sensitivities_other() {
      this.$emit('update:sensitivities-other', this.sensitivities_other)
    },
  },
  methods: {
  },
}
</script>
