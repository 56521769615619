import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('NoteSection',{attrs:{"label":"Medications"}},[_c('btn',{staticClass:"col-sm-12",attrs:{"label":"Tap here to select medications","icon":_vm.icons.mdiPill},on:{"click":function($event){_vm.modalState = true}}}),_c(VDialog,{attrs:{"max-width":"1024","scrollable":""},on:{"click:outside":function($event){return _vm.clickOutside()}},model:{value:(_vm.modalState),callback:function ($$v) {_vm.modalState=$$v},expression:"modalState"}},[_c(VCard,[_c(VCardTitle,{ref:"medicationsTitle",staticClass:"pb-0"},[_vm._v(" Medications ")]),_c(VDivider),_c(VCardText,{staticStyle:{"max-height":"800px"}},[_c('check-boxes',{staticClass:"col-sm-12",attrs:{"items":_vm.medications,"columns":"4","column-sort":"","return-object":""},model:{value:(_vm.medication_values),callback:function ($$v) {_vm.medication_values=$$v},expression:"medication_values"}})],1),(_vm.isOtherMedicine)?_c(VDivider):_vm._e(),_c(VCardText,{staticStyle:{"max-height":"150px"}},[(_vm.isOtherMedicine)?_c('text-area',{staticClass:"col-sm-12",attrs:{"label":"Enter other medications...","counter":"3200","maxlength":"3200","required":""},model:{value:(_vm.medications_other),callback:function ($$v) {_vm.medications_other=$$v},expression:"medications_other"}}):_vm._e()],1),_c(VDivider),_c(VCardActions,[_c('btn',{attrs:{"label":"Clear All","color":"secondary","icon":_vm.icons.mdiCheckboxMultipleBlankOutline,"disabled":_vm.isSigned},on:{"click":_vm.clear}}),_c(VSpacer),_c('btn',{attrs:{"label":"Done","icon":_vm.icons.mdiCheck,"disabled":_vm.isOtherMedicine && !_vm.medications_other},on:{"click":_vm.save}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }