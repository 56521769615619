<template>
  <v-dialog
    v-if="opNoteWizardData"
    v-model="modalState"
    width="850"
    persistent
  >
    <v-card>
      <v-form v-model="formValid">
        <v-card-title ref="opNoteWizardTitle">
          Operative Note Assistant Wizard
        </v-card-title>

        <v-row class="note-section-row">
          <div class="note-section-container col-sm-4p5">
            <NoteSection label="Consent Obtained From">
              <radio-buttons
                v-model="opNoteWizardData.consent_selected"
                :items="consentOptions"
                clearable
                column
                @change="consentChanged"
              ></radio-buttons>
              <text-area
                v-model="opNoteWizardData.consent_text"
                label="Consent description..."
                counter="255"
                maxlength="255"
                rows="2"
              ></text-area>
            </NoteSection>
          </div>
          <div class="note-section-container col-sm-7p5">
            <NoteSection label="Positioning">
              <select-box
                v-model="opNoteWizardData.positioning_id"
                :items="opNotePositionings"
                label="Positioning"
              >
              </select-box>
            </NoteSection>

            <NoteSection
              label="Cleansing"
              custom-class="my-6"
            >
              <select-box
                v-model="opNoteWizardData.cleansing_id"
                :items="opNoteCleansings"
                label="Cleansing"
                class="col-sm-6"
                dense
              >
              </select-box>
              <select-box
                v-model="opNoteWizardData.cleansing_2_id"
                :items="opNoteCleansings"
                label="Cleansing Other"
                class="col-sm-6"
                dense
              >
              </select-box>
            </NoteSection>

            <NoteSection label="Pain Management Via">
              <select-box
                v-model="opNoteWizardData.pain_management_id"
                :items="opNotePainManagements"
                label="Method 1"
                class="col-sm-6"
                dense
                @change="clearPainManagementText()"
              >
              </select-box>

              <!-- Remove `Other` option from second opNotePainManagements selection TXP-287 -->
              <select-box
                v-model="opNoteWizardData.pain_management_2_id"
                :items="removeOther()"
                label="Method 2"
                class="col-sm-6"
                dense
              >
              </select-box>

              <!-- `Other` id === 6 -->
              <text-area
                v-if="opNoteWizardData.pain_management_id === 6"
                v-model="opNoteWizardData.pain_management_other"
                label="Other Description..."
                counter="255"
                maxlength="255"
                rows="2"
                :required="opNoteWizardData.pain_management_id === 6"
              >
              </text-area>
            </NoteSection>
          </div>
        </v-row>

        <NoteSection label="Technique">
          <select-box
            v-model="opNoteWizardData.incision_type_id"
            :items="opNoteIncisionTypes"
            label="Incision Type"
            class="col-sm-3p5"
          >
          </select-box>
          <select-box
            v-model="opNoteWizardData.instrument_id"
            :items="opNoteInstruments"
            label="Using Instrument"
            class="col-sm-8p5"
          >
          </select-box>
          <v-spacer class="col-sm-3p5"></v-spacer>
          <select-box
            v-model="opNoteWizardData.instrument_2_id"
            :items="opNoteInstruments"
            label="Additional Instrument #1"
            class="col-sm-4p25"
            dense
          >
          </select-box>
          <select-box
            v-model="opNoteWizardData.instrument_3_id"
            :items="opNoteInstruments"
            label="Additional Instrument #2"
            class="col-sm-4p25"
            dense
          >
          </select-box>
        </NoteSection>

        <NoteSection label="Visualized Tissue">
          <select-box
            v-model="opNoteWizardData.visualized_muscle_id"
            :items="opNoteVisualizedMuscles"
            label="Visualized Muscle"
            class="col-sm-6"
          >
          </select-box>
          <select-box
            v-model="opNoteWizardData.visualized_bone_id"
            :items="opNoteVisualizedBones"
            label="Visualized Bone"
            class="col-sm-6"
          >
          </select-box>
          <text-area
            v-if="isVisualizedMuscleOther"
            v-model="opNoteWizardData.visualized_muscle_other_text"
            :required="isVisualizedMuscleOther"
            label="Enter other Visualized Muscle..."
            class="col-sm-6"
            counter="800"
            maxlength="800"
          ></text-area>
          <v-spacer
            v-else
            class="col-sm-6"
          ></v-spacer>
          <text-area
            v-if="isVisualizedBoneOther"
            v-model="opNoteWizardData.visualized_bone_other_text"
            :required="isVisualizedBoneOther"
            label="Enter other Visualized Bone..."
            class="col-sm-6"
            counter="400"
            maxlength="400"
          ></text-area>
        </NoteSection>

        <NoteSection label="Estimated Blood Loss / Hemostasis / Dressings">
          <select-box
            v-model="opNoteWizardData.estimated_blood_loss_id"
            :items="opNoteBloodLosses"
            label="Est. Blood Loss (cc)"
            class="col-sm-3p5"
            :required="isDebridementPerformed"
          >
          </select-box>
          <div class="col-sm-4p25">
            <select-box
              v-model="opNoteWizardData.hemostasis_id"
              name="hemostasis"
              :items="opNoteHemostases"
              label="Hemostasis"
              :required="hemostasisValidation"
            >
            </select-box>
            <select-box
              v-model="opNoteWizardData.hemostasis_2_id"
              name="hemostasisOther"
              :items="opNoteHemostases"
              label="Hemostasis Other"
              :required="hemostasisValidation"
            >
            </select-box>
            <text-area
              v-model="opNoteWizardData.hemostasis_text"
              name="hemostasisAdditional"
              label="Additional hemostasis..."
              counter="255"
              maxlength="255"
              :required="hemostasisValidation"
            ></text-area>
          </div>
          <div class="col-sm-4p25">
            <select-box
              v-model="woundTreatment.dressing_used_1"
              :items="dressings"
              label="Dressing Used"
              dense
              :required="dressingValidation"
              :disabled="disableField.dressingUsed1"
              @input="clearDressings($event)"
            >
            </select-box>
            <select-box
              v-model="woundTreatment.dressing_used_2"
              :items="dressings.filter(x => x.title !== 'None')"
              label="Additional Dressing"
              :required="dressingValidation"
              :disabled="disableField.dressingUsed2"
              dense
              @input="clearDressings"
            >
            </select-box>
            <text-area
              v-model="woundTreatment.dressing_used_other"
              :disabled="disableField.dressingUsedOther"
              label="Enter other dressings..."
              :required="dressingValidation"
            ></text-area>
          </div>
        </NoteSection>

        <v-card-actions class="sticky-bottom">
          <!-- exit -->
          <btn
            label="Exit"
            color="error"
            :icon="icons.mdiClose"
            @click="exit"
          ></btn>

          <v-spacer></v-spacer>

          <!-- clear -->
          <btn
            label="Clear All"
            color="secondary"
            :icon="icons.mdiUndoVariant"
            @click="clear"
          ></btn>

          <v-spacer></v-spacer>

          <!-- save -->
          <btn
            label="Save"
            :icon="icons.mdiCheck"
            :disabled="!formValid"
            @click="save"
          ></btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiCheck,
  mdiClose,
  mdiUndoVariant,
} from '@mdi/js'
import { mapGetters } from 'vuex'

const initialData = {
  treatment_id: null,
  consent_selected: null,
  consent_text: null,
  positioning_id: null,
  cleansing_id: null,
  cleansing_2_id: null,
  pain_management_id: null,
  pain_management_2_id: null,
  incision_type_id: null,
  instrument_id: null,
  instrument_2_id: null,
  instrument_3_id: null,
  visualized_bone_id: null,
  visualized_bone_other_text: null,
  visualized_muscle_id: null,
  visualized_muscle_other_text: null,
  estimated_blood_loss_id: null,
  hemostasis_id: null,
  hemostasis_2_id: null,
  hemostasis_text: null,
}

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    woundTreatment: {
      type: Object,
      default: null,
    },
    treatmentId: {
      type: String,
      default: null,
    },
    modalState: {
      type: Boolean,
      default: false,
    },
    procedureId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      formValid: false,
      opNoteWizardData: {
        ...initialData,
        treatment_id: this.treatmentId,
        ...this.$lodash.cloneDeep(this.value),
      },
      icons: {
        mdiCheck, mdiUndoVariant, mdiClose,
      },
      consentOptions: [
        { title: 'Family' },
        { title: 'Patient' },
        { title: 'Patient conservator' },
        { title: 'Patient spouse' },
        { title: 'Two doctors' },
      ],
      disableField: {
        dressingUsed1: false,
        dressingUsed2: false,
        dressingUsedOther: false,
      },
    }
  },
  computed: {
    ...mapGetters('encounters', [
      'opNoteBloodLosses',
      'opNoteCleansings',
      'opNoteHemostases',
      'opNoteIncisionTypes',
      'opNoteInstruments',
      'opNotePainManagements',
      'opNotePositionings',
      'opNoteVisualizedBones',
      'opNoteVisualizedMuscles',
      'dressings',
    ]),
    isDebridementPerformed() {
      const procedure = this.$store.getters['baseData/procedureFromId'](this.procedureId)
      return procedure ? procedure.toLowerCase().includes('debrid') : false
    },
    hemostasisValidation() {
      return this.isDebridementPerformed && !(this.opNoteWizardData.hemostasis_id || this.opNoteWizardData.hemostasis_2_id || this.opNoteWizardData.hemostasis_text)
    },
    dressingValidation() {
      return !(this.woundTreatment.dressing_used_1 || this.woundTreatment.dressing_used_2 || this.woundTreatment.dressing_used_other)
    },
    isVisualizedMuscleOther() {
      // Search for currently selected visualizedMuscle
      const visualizedMuscle = this.opNoteVisualizedMuscles[
        this.opNoteVisualizedMuscles.findIndex(x => x.id === this.opNoteWizardData.visualized_muscle_id)]?.title

      return visualizedMuscle === 'Other'
    },
    isVisualizedBoneOther() {
      // Search for currently selected visualizedBone
      const visualizedBone = this.opNoteVisualizedBones[
        this.opNoteVisualizedBones.findIndex(x => x.id === this.opNoteWizardData.visualized_bone_id)]?.title

      return visualizedBone === 'Other'
    },
  },
  watch: {
    modalState() {
      // Scroll to top of modal
      const modalTitle = this.$refs.opNoteWizardTitle
      if (modalTitle) {
        modalTitle.scrollIntoView(true)
      }

      if (this.modalState) {
        this.opNoteWizardData = {
          ...initialData,
          treatment_id: this.treatmentId,
          ...this.$lodash.cloneDeep(this.value),
        }
      }
    },
  },
  mounted() {
  },
  methods: {
    clearDressings(event) {
      // console.log(event)
      // console.log(this.woundTreatment.dressing_used_1)
      if (this.woundTreatment.dressing_used_1 === 'None') {
        this.$nextTick(() => {
          // console.log(this.woundTreatment.dressing_used_1)
          this.woundTreatment.dressing_used_2 = null
          this.woundTreatment.dressing_used_other = null
          this.disableField.dressingUsed2 = true
          this.disableField.dressingUsedOther = true
        })
      } else if (this.woundTreatment.dressing_used_2 === 'None') {
        this.$nextTick(() => {
          this.woundTreatment.dressing_used_1 = null
          this.woundTreatment.dressing_used_other = null
          this.disableField.dressingUsed1 = true
          this.disableField.dressingUsedOther = true
        })
      } else {
        this.disableField.dressingUsed1 = false
        this.disableField.dressingUsed2 = false
        this.disableField.dressingUsedOther = false
      }
    },
    consentChanged() {
      // If consent_text is blank, set consent_text to consent_selected
      if (!this.opNoteWizardData.consent_text) {
        this.$nextTick(() => {
          this.opNoteWizardData.consent_text = this.opNoteWizardData.consent_selected
        })
      } else {
        const consentToReplace = this.opNoteWizardData.consent_selected
        this.$nextTick(() => {
          if (this.consentOptions.some(x => this.opNoteWizardData.consent_text.includes(x.title))) {
            this.opNoteWizardData.consent_text = this.opNoteWizardData.consent_text.replace(consentToReplace, this.opNoteWizardData.consent_selected || '')
          } else {
            this.opNoteWizardData.consent_text = this.opNoteWizardData.consent_selected.concat(' ', this.opNoteWizardData.consent_text)
          }
        })
      }
    },
    clearPainManagementText() {
      if (this.opNoteWizardData.pain_management_id !== 6) this.opNoteWizardData.pain_management_other = null
    },
    exit() {
      this.$root.confirm({
        title: 'Discard Changes and Exit?',
        body: 'Are you sure you wish to discard changes and exit?',
        cancel: 'Cancel',
        confirm: 'Discard / Exit',
        confirmColor: 'error',
      }).then(result => {
        if (result) {
          this.$emit('update:modal-state', false)
        }
      })
    },
    clear() {
      this.$root.confirm({
        title: 'Clear All Operative Note Options?',
        body: 'Are you sure you wish to clear all operative note options? (will not clear dressings)',
        cancel: 'Cancel',
        confirm: 'Clear',
      }).then(result => {
        if (result) {
          this.opNoteWizardData = { ...initialData, treatment_id: this.treatmentId }
        }
      })
    },
    save() {
      this.$emit('input', this.opNoteWizardData)
      this.$emit('update:modal-state', false)
    },
    removeOther() {
      return this.opNotePainManagements.filter(method => method.title !== 'Other')
    },
  },
}
</script>
