<template>
  <NoteSection
    label="Past Medical History"
  >
    <!-- Old display method, changed to multi-select list box for higher user input speed
    <select-box
      v-for="(item, i) in section.medical_histories"
      :key="`item-${i}-${section.medical_histories.length}`"
      v-model="section.medical_histories[i]"
      :items="medicalHistories"
      :append-icon="icons.mdiCloseThick"
      label="Select from list"
      class="col-sm-4 past-medical-history"
      clearable="false"
      hide-details
      return-object
      @click:append="section.medical_histories.splice(i, 1)"
    ></select-box>
    <div class="col-sm-4">
      <btn
        label="Add Medical History"
        class="past-medical-history"
        :icon="icons.mdiTablePlus"
        :disabled="isSigned"
        @click="section.medical_histories.push({id:null})"
      ></btn>
    </div>
    -->
    <select-box
      v-model="section.medical_histories"
      :items="medicalHistories"
      label="Choose past medical histories"
      class="col-sm-12"
      multiple
      return-object
      :required="isMedicalHistoriesRequired"
    ></select-box>
    <text-area
      v-model="section.medical_histories_other"
      label="Enter other past medical history..."
      class="col-sm-12"
      counter="8200"
      maxlength="8200"
    ></text-area>
    <radio-buttons
      v-if="isHistoryDiabetes"
      v-model="section.neuropathy"
      :items="query"
      label="Neuropathy"
      class="col-sm-12"
      required
      row
    ></radio-buttons>
    <select-box
      v-if="isHistoryDiabetes && section.neuropathy"
      v-model="section.neuropathy_type"
      :items="neuropathyTypes"
      label="Neuropathy Type"
      class="col-sm-6"
      required
    ></select-box>
    <select-box
      v-if="isHistoryDiabetes && section.neuropathy"
      v-model="section.neuropathy_severity"
      :items="neuropathySeverities"
      label="Neuropathy Severity"
      class="col-sm-6"
      required
    ></select-box>
  </NoteSection>
</template>

<script>
import { mapGetters } from 'vuex'
import { mdiTablePlus, mdiCloseThick } from '@mdi/js'

export default {
  props: {
    value: {
      type: Array,
      default: null,
    },
    medicalHistoriesOther: {
      type: String,
      default: null,
    },
    neuropathy: {
      type: [Boolean, Number],
      default: null,
    },
    neuropathyType: {
      type: String,
      default: null,
    },
    neuropathySeverity: {
      type: String,
      default: null,
    },
    isSigned: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      section: {
        medical_histories: [...this.value],
        medical_histories_other: this.medicalHistoriesOther,
        neuropathy: this.neuropathy,
        neuropathy_type: this.neuropathyType,
        neuropathy_severity: this.neuropathySeverity,
      },
      icons: { mdiTablePlus, mdiCloseThick },
    }
  },
  computed: {
    ...mapGetters('baseData', ['query']),
    ...mapGetters('encounters', ['medicalHistories', 'neuropathyTypes', 'neuropathySeverities']),
    isHistoryDiabetes() {
      return this.section.medical_histories.some(x => x.id === 12)
      || this.section.medical_histories.some(x => x.id === 13)
      || this.$custom.strContains(this.section.medical_histories_other, 'diabetes')
    },
    isMedicalHistoriesRequired() {
      // Ensure medical_histories_other is a string and trim whitespace
      const trimmedValue = typeof this.section.medical_histories_other === 'string'
        ? this.section.medical_histories_other.trim()
        : ''
      // Return true if the trimmed value is empty, indicating that the field is required
      return !trimmedValue
    },
  },
  watch: {
    section: {
      deep: true,
      handler() {
        this.$emit('input', this.section.medical_histories)
        this.$emit('update:medical-histories-other', this.section.medical_histories_other)
        this.$emit('update:neuropathy', this.section.neuropathy)
        this.$emit('update:neuropathy-type', this.section.neuropathy_type)
        this.$emit('update:neuropathy-severity', this.section.neuropathy_severity)
      },
    },

    // Clear all neuropathy section if medical history does not include diabetes.
    'section.medical_histories': {
      handler() {
        if (!this.isHistoryDiabetes) {
          this.section.neuropathy = null
          this.section.neuropathy_type = null
          this.section.neuropathy_severity = null
        }
      },
    },

    'section.medical_histories_other': {
      handler() {
        if (!this.isHistoryDiabetes) {
          this.section.neuropathy = null
          this.section.neuropathy_type = null
          this.section.neuropathy_severity = null
        }
      },
    },
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.past-medical-history {
  margin-bottom: 15px !important;
  &.v-btn {
    width: 100%;
    margin-bottom: 19px !important;
  }
}
</style>
