<template>
  <chart
    :key="index"
    :series="graph"
    :options="options"
    :type="type"
    :width="width"
    :height="height"
  ></chart>
</template>

<script>
import Chart from 'vue-apexcharts'
import themeConfig from '@themeConfig'

export default {
  components: { Chart },
  props: {
    value: {
      type: Array,
      default: null,
    },
    labels: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    xTitle: {
      type: String,
      default: '',
    },
    yTitle: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'line',
    },
    width: {
      type: [String, Number],
      default: '100%',
    },
    height: {
      type: [String, Number],
      default: 350,
    },
    min: {
      type: [String, Number],
      default: null,
    },
    max: {
      type: [String, Number],
      default: null,
    },
    colors: {
      type: Array,
      default: () => ['primary', 'success', 'error', 'warning', 'secondary'],
    },
    color: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      index: 0,
      graph: this.value,
      options: {
        title: {
          text: this.title,
          align: 'center',
        },
        xaxis: {
          title: { text: this.xTitle },
          categories: this.labels,
        },
        yaxis: {
          title: { text: this.yTitle },
        },
        colors: this.computedColors(),
        markers: {
          size: 4,
          strokeWidth: 2,
          strokeOpacity: 0.8,
          hover: {
            sizeOffset: 4,
          },
        },
        grid: { xaxis: { lines: { show: true } } },
        dataLabels: { enabled: false },
        stroke: { curve: 'straight' }, // straight, smooth
        chart: {
          zoom: { enabled: false },
          toolbar: { show: false },
        },
        tooltip: {
          custom(data) {
            return `${'<div class="px-1 py-50"><span>'}${
              data.series[data.seriesIndex][data.dataPointIndex]
            }cm²</span></div>`
          },
        },
      },
    }
  },
  computed: {},
  watch: {
    min() {
      this.setMin()
    },
    max() {
      this.setMax()
    },
  },
  mounted() {
    this.setMin()
    this.setMax()
    this.index += 1
  },
  methods: {
    setMin() {
      if (this.min !== null) {
        this.options.yaxis.min = Number(this.min)
        this.index += 1
      }
    },
    setMax() {
      if (this.max !== null) {
        this.options.yaxis.max = Number(this.max)
        this.index += 1
      }
    },
    computedColors() {
      if (this.color) {
        return [this.colorLookup(this.color)]
      }
      if (Array.isArray(this.colors)) {
        const colors = []
        this.colors.forEach(color => {
          colors.push(this.colorLookup(color))
        })

        return colors
      }

      return [themeConfig.themes.light.primary]
    },
    colorLookup(color) {
      switch (color) {
        case 'primary': return themeConfig.themes.light.primary
        case 'secondary': return themeConfig.themes.light.secondary
        case 'accent': return themeConfig.themes.light.accent
        case 'error': return themeConfig.themes.light.error
        case 'info': return themeConfig.themes.light.info
        case 'success': return themeConfig.themes.light.success
        case 'warning': return themeConfig.themes.light.warning
        default: return color
      }
    },
  },
}
</script>
