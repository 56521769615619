<template>
  <NoteSection
    label="Review of Systems"
  >
    <text-area
      v-model="systems_review"
      class="col-sm-12"
      label="Enter review of systems..."
      required
      ai
      counter="1300"
      maxlength="1300"
      word-count="8"
      @click:prepend="generateSystemsReview"
      @input="$emit('input', systems_review)"
    ></text-area>
  </NoteSection>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    medicalHistoryList: {
      type: Array,
      default: () => [],
    },
    medicalHistoryOther: {
      type: String,
      default: null,
    },
    riskFactorList: {
      type: Array,
      default: () => [],
    },
    riskFactorOther: {
      type: String,
      default: null,
    },

    /*
    patientDob: {
      type: String,
      default: null,
    },
    patientGender: {
      type: String,
      default: 'person',
    },
    therapeuticAnticoagulant: {
      type: Boolean,
      default: false,
    },
    */
    neuropathy: {
      type: Boolean,
      default: false,
    },
    neuropathyType: {
      type: String,
      default: null,
    },
    neuropathySeverity: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      systems_review: this.value,
    }
  },
  computed: {
    /*
    age() {
      return this.$custom.ageInYears(this.patientDob)
    },
    */
  },
  watch: {},
  methods: {
    generateSystemsReview() {
      let systemsReview = ''

      // Organize combined medicalHistoryList & riskFactorList into category groups
      const categoryGroups = { Other: [] }
      this.medicalHistoryList.concat(this.riskFactorList).forEach(item => {
        const categories = item.category?.split(',')
        categories.forEach(cat => {
          const category = cat.trim() ? cat.trim() : 'Other'
          if (!categoryGroups[category]) categoryGroups[category] = []
          categoryGroups[category].push(item.title)
        })
      })

      // Add neuropathy severity and type
      if (this.neuropathy && this.neuropathySeverity && this.neuropathyType) {
        // If there are no other neuropathy values, we need to create an empty array first for neuro in order to push values.
        if (!categoryGroups.Neuro) {
          categoryGroups.Neuro = []
        }
        categoryGroups.Neuro.push(`${this.neuropathySeverity} ${this.neuropathyType?.toLowerCase()} neuropathy`)
      }

      // Add other medical history and risk factors
      if (this.medicalHistoryOther) {
        categoryGroups.Other.push(this.medicalHistoryOther)
      }
      if (this.riskFactorOther) {
        categoryGroups.Other.push(this.riskFactorOther)
      }

      // Generate output in particular order
      const otherCategory = categoryGroups.Other.length > 0 ? 'Other' : null // Remove category "other" if array is empty.
      const order = ['Pulmonary', 'Cardiac', 'Vascular', 'Neuro', 'Immuno', otherCategory].filter(Boolean)
      order.forEach(category => {
        systemsReview += ` ${category}: ${categoryGroups[category]
          ? this.$custom.toListComma(categoryGroups[category], null, 'none')
          : 'None'}, `
      })

      this.systems_review = this.$custom.clean(systemsReview, 'Systems Review')
      this.$emit('input', this.systems_review)
    },

    /* Old text generation
    generateSystemsReview() {
      let systemsReview = ''
      const systemItemTitles = []
      if (this.medicalHistoryList) {
        this.medicalHistoryList.forEach(medicalHistory => {
          if (medicalHistory.title !== undefined) {
            systemItemTitles.push(medicalHistory.title)
          }
        })
      }
      const riskFactorTitles = []
      if (this.riskFactorList) {
        this.riskFactorList.forEach(riskFactor => {
          if (riskFactor.title !== undefined) {
            riskFactorTitles.push(riskFactor.title)
            if (['Atrial fibrillation', 'CAD', 'CHF', 'CVA/Stroke', 'Anemia', 'Hypertension', 'Obesity', 'COPD', 'Respiratory Failure', 'CKD', 'PAD', 'PVD', 'POD', 'Diabetes Type 1', 'Diabetes Type 2', 'Renal Failure', 'Hyperlipidemia', 'VSD', "Alzheimer's", 'Cognitive Impairment', 'Dementia', 'Depression', 'Dysphagia', 'Paraplegia', 'Quadriplegia', 'Spasticity', 'TBI', 'CVA/Stroke', 'Encephalopathy', 'Traumatic Brain Injury', 'Hemiplegia'].includes(riskFactor.title)) {
              systemItemTitles.push(riskFactor.title)
            }
          }
        })
      }
      systemsReview = this.$custom.randomString(['This', 'The patient', 'The treated patient', 'The patient examined', 'The patient seen', 'The patient observed', 'The patient under observation'])
      systemsReview += 'is '
      systemsReview += this.$custom.articleAge(this.age)
      if (this.patientGender) {
        systemsReview += this.$custom.toLowerCase(this.patientGender)
        systemsReview += ' '
      }
      if (systemItemTitles.length > 0) {
        systemsReview += this.$custom.randomString(['with the following diagnoses and risk factors:', 'who has the following diagnoses and risk factors:', 'having diagnoses and risk factors as follows:', 'who was found to have the following risk factors and diagnoses:', 'for whom the following diagnoses/risk factors were found:', 'for whom the following diagnoses/risk factors were determined:', 'determined to have diagnoses/risk factors as follows:'])
        systemsReview += this.$custom.toListComma(systemItemTitles, null, 'lc')
      }
      if (this.medicalHistoryOther && this.medicalHistoryOther?.length > 0) {
        systemsReview += this.$custom.randomString(['in addition to', 'as well as', 'along with', 'and additionally', 'and in addition'])
        systemsReview += this.$custom.textAreaFlat(this.medicalHistoryOther)
      }
      systemsReview += ' '
      if (riskFactorTitles.length > 0) {
        systemsReview += this.$custom.randomString(['with', 'having', 'and'])
        systemsReview += this.$custom.randomString(['these', 'the following'])
        systemsReview += 'risk factors: '
        systemsReview += this.$custom.toListCommaAnd(riskFactorTitles, null, 'lc')
      }
      if (this.riskFactorOther && this.riskFactorOther?.length > 0) {
        if (riskFactorTitles.length > 0) {
          systemsReview += ', as well as '
        } else {
          systemsReview += 'with the following risk factors: '
        }
        systemsReview += this.$custom.textAreaFlat(this.riskFactorOther)
      }
      systemsReview += '.  '
      if (this.therapeuticAnticoagulant) {
        systemsReview += '  NOTE: PATIENT RECEIVING THERAPEUTIC ANTICOAGULANT.  '
      }
      this.systems_review = this.$custom.clean(systemsReview, 'Systems Review')
      this.$emit('input', this.systems_review)
    },
    */
  },
}
</script>
