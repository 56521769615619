<template>
  <NoteSection
    label="Mobility &amp; Continence"
  >
    <select-box
      v-model="section.mobility"
      :items="mobilityOptions"
      label="Mobility"
      class="col-sm-6"
      required
    >
    </select-box>
    <select-box
      v-model="section.continence"
      :items="continenceOptions"
      label="Continence"
      class="col-sm-6"
      required
    >
    </select-box>
    <text-area
      v-if="isMobilityOther"
      v-model="section.mobility_other"
      :required="isMobilityOther"
      label="Enter other mobility..."
      class="col-sm-6"
      counter="500"
      maxlength="500"
    ></text-area>
    <v-spacer
      v-else
      class="col-sm-6"
    ></v-spacer>
    <text-area
      v-if="isContinenceOther"
      v-model="section.continence_other"
      :required="isContinenceOther"
      label="Enter other continence..."
      class="col-sm-6"
      counter="600"
      maxlength="600"
    ></text-area>
  </NoteSection>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    mobility: {
      type: String,
      default: null,
    },
    continence: {
      type: String,
      default: null,
    },
    mobilityOther: {
      type: String,
      default: null,
    },
    continenceOther: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      section: {
        mobility: this.mobility,
        continence: this.continence,
        mobility_other: this.mobilityOther,
        continence_other: this.continenceOther,
      },
    }
  },
  computed: {
    ...mapGetters('encounters', ['mobilityOptions', 'continenceOptions']),
    isMobilityOther() {
      return this.section.mobility === 'Other'
    },
    isContinenceOther() {
      return this.section.continence === 'Other'
    },
  },
  watch: {
    section: {
      deep: true,
      handler() {
        this.$emit('update:mobility', this.section.mobility)
        this.$emit('update:continence', this.section.continence)
        this.$emit('update:mobility-other', this.section.mobility_other)
        this.$emit('update:continence-other', this.section.continence_other)
      },
    },
  },
  methods: {
  },
}
</script>
