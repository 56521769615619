<template>
  <NoteSection>
    <checkbox
      v-model="therapeutic_anticoagulant"
      :items="query"
      label="Patient on anticoagulant?"
      class="col-sm-6"
      @change="anticoagulantMessage"
    ></checkbox>
    <checkbox
      v-model="low_loss_mattress"
      :items="query"
      label="Patient has low air loss mattress?"
      class="col-sm-6"
      @change="$emit('update:low-loss-mattress', low_loss_mattress)"
    ></checkbox>
  </NoteSection>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    therapeuticAnticoagulant: {
      type: [Boolean, Number],
      default: null,
    },
    lowLossMattress: {
      type: [Boolean, Number],
      default: null,
    },
  },
  data() {
    return {
      therapeutic_anticoagulant: this.therapeuticAnticoagulant,
      low_loss_mattress: this.lowLossMattress,
    }
  },
  computed: {
    ...mapGetters('baseData', ['query']),
  },
  watch: {
  },
  methods: {
    anticoagulantMessage(value) {
      this.$emit('update:therapeutic-anticoagulant', value)
      if (value) {
        this.$root.confirm({
          title: 'Is Patient on Therapeutic Anticoagulant?',
          body: `
            <p>This is a reminder area to check to see if the patient is on anti-coagulation medications
            because these medications may cause significant hemorrhage post-debridement.</p>

            <p>Contraindications to debridement include any therapeutic anticoagulants:</p>
            <b>
              <ul>
                <li>Coumadin (warfarin)</li>
                <li>Weight-based Lovenox</li>
                <li>Eliquis (apaxiban)</li>
                <li>Pradaxa (dabigatran)</li>
                <li>Xarelto (rivaroxaban)</li>
                <li>Savaysa (edoxaban)</li>
                <li>Plavix (clopidogrel)</li>
              </ul>
            </b>
            <p>DVT Prophylaxis doses of Heparin (20 mg sq bid) Lovenox 40 mg sq daily are not contraindications to debridement.</p>
            <p>If the patient <b><i>IS</i></b> on therapeutic anti-coagulation, after approval from the patient's primary physician, you may dc anticoagulant, start Lovenox 1mg/ (kg of patient body weight) bid for one week.</p>
            <p>Ensure INR is below 1.2 and then hold Lovenox night before and morning of debridement. Restart Lovenox on the evening after debridement.</p>
            <p>You may repeat the process until wound no longer needs debridement.</p>
          `,
          large: true,
          html: true,
          cancel: false,
          persistent: false,
        })
      }
    },
  },
}
</script>
