<template>
  <NoteSection
    label="Diabetic Risk Review"
  >
    <v-tabs
      v-model="activeTab"
      fixed-tabs
      color="success"
      class="col-sm-12 mb-5"
    >
      <v-tab
        :class="dfuInvalid && 'required-field required-tab'"
        v-if="!previousDfuRiskEncounter"
      >
        <strong v-if="dfuInvalid">*</strong>
        DFU Risk Review
      </v-tab>
      <v-tab
        :class="footwearInvalid && 'required-field required-tab'"
        v-if="!previousFootwearExamEncounter"
      >
        <strong v-if="footwearInvalid">*</strong>
        Footwear Eval
      </v-tab>
      <v-tab
        :class="a1cInvalid && 'required-field required-tab'"
        v-if="!previousA1cLevelEncounter"
      >
        <strong v-if="a1cInvalid">*</strong>
        A1c Levels
      </v-tab>

      <!-- dfu risk review -->
      <v-tab-item
        eager
        v-if="!previousDfuRiskEncounter"
      >
        <help-icon
          title="DFU Risk Review"
          body="<p>Patients may not understand the risk factors or the need for preventive care for diabetic foot ulcers (DFUs).</p>
                <p>Identify and educate patients who are at risk for the development of DFUs.</p>
                <p>Indicate if the patient was informed of DFU risk or if the patient was unable to accept DFU risk information.</p>"
        ></help-icon>
        <radio-buttons
          v-model="dfu_risk"
          :items="footwear_evaluation === 'not accepted' ? dfuIndicatorsNotAcceptedOptions : dfuIndicatorsOptions"
          label="DFU Indicators"
          column
          clearable
          :required="dfuRiskRequired"
          @change="dfuRiskChanged"
        ></radio-buttons>
        <select-box
          v-if="dfu_risk === 'not accepted'"
          v-model="dfu_risk_reason"
          :items="reasonOptions"
          label="Reason patient unable to accept..."
          class="col-sm-12"
          required
        >
        </select-box>
      </v-tab-item>

      <!-- footwear eval -->
      <v-tab-item
        eager
        v-if="!previousFootwearExamEncounter"
      >
        <radio-buttons
          v-model="footwear_evaluation"
          :items="dfu_risk === 'not accepted' ? footwearEvaluationNotAcceptedOptions : footwearEvaluationOptions"
          label="Footwear Evaluation"
          column
          clearable
          :required="footwearEvaluationRequired"
          @change="footwearEvaluationChanged"
        ></radio-buttons>
        <select-box
          v-if="footwear_evaluation === 'not accepted'"
          v-model="footwear_evaluation_reason"
          :items="reasonOptions"
          label="Reason patient unable to accept..."
          class="col-sm-12"
          required
        >
        </select-box>
        <check-boxes
          v-if="footwear_evaluation === 'exam performed'"
          v-model="footwear_notable_issues"
          :items="footwearNotableIssuesOptions"
          label="Notable Issues"
          class="col-sm-12 mt--1"
          distinct="None"
          columns="3"
          column-sort
          return-object
          required
        ></check-boxes>
        <div v-if="footwear_evaluation === 'exam performed'">
          <label for="footwear_change">Footwear change recommended?</label>
          <v-checkbox
            v-model="footwear_exam_reason"
            id="footwear_change"
            label="Yes"
            value="Footwear change recommended"
          ></v-checkbox>
        </div>
      </v-tab-item>
      <!-- a1c levels -->
      <v-tab-item
        eager
        v-if="!previousA1cLevelEncounter"
      >
        <help-icon
          title="A1c Levels"
          body="<p>People with diabetes are at increased risk of serious health complications including:</p>
                <ul>
                  <li>Vision loss</li>
                  <li>Heart disease</li>
                  <li>Stroke</li>
                  <li>Kidney failure</li>
                  <li>Amputation of toes, feet or legs</li>
                  <li>Premature death</li>
                </ul>
                <p>Reducing A1c blood levels results by 1 percentage point (e.g., from 8.0% to 7.0%) helps reduce the risk of microvascular complications (eye, kidney and nerve diseases) by as much as 40%.</p>"
        ></help-icon>
        <radio-buttons
          :disabled="isA1cNotApplicable"
          disabled-custom-text="Not applicable due to patient's age"
          v-model="a1c_level"
          :items="a1cLevelsOptions"
          label="A1c Level"
          columns="2"
          column-sort
          clearable
          :required="a1cLevelRequired"
        ></radio-buttons>
      </v-tab-item>
    </v-tabs>
  </NoteSection>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    dfuRisk: {
      type: String,
      default: null,
    },
    dfuRiskReason: {
      type: String,
      default: null,
    },
    footwearEvaluation: {
      type: String,
      default: null,
    },
    footwearEvaluationReason: {
      type: String,
      default: null,
    },
    footwearExamReason: {
      type: String,
      default: null,
    },
    footwearNotableIssues: {
      type: Array,
      default: null,
    },
    a1cLevel: {
      type: String,
      default: null,
    },
    highlightForm: {
      type: Boolean,
      default: null,
    },
    previousDfuRiskEncounter: {
      type: Object,
      default: null,
    },
    previousFootwearExamEncounter: {
      type: Object,
      default: null,
    },
    previousA1cLevelEncounter: {
      type: Object,
      default: null,
    },
    ptAge: {
      type: Number,
      default: null,
    },

    /* Disabled in SWC-838 (forced to not required)
    dfuRiskRequired: {
      type: Boolean,
      default: null,
    },
    footwearEvaluationRequired: {
      type: Boolean,
      default: null,
    },
    a1cLevelRequired: {
      type: Boolean,
      default: null,
    },
    */
  },
  data() {
    return {
      activeTab: 0,
      dfu_risk: this.dfuRisk,
      dfu_risk_reason: this.dfuRiskReason,
      footwear_evaluation: this.footwearEvaluation,
      footwear_evaluation_reason: this.footwearEvaluationReason,
      footwear_exam_reason: this.footwearExamReason,
      footwear_notable_issues: this.footwearNotableIssues,
      a1c_level: this.a1cLevel,
      footwearExamReasonOptions: [
        { title: 'Footwear change recommended' },
      ],
      reasonOptions: [
        { title: 'Patient non-communicative' },
        { title: 'Patient refused consultation' },
      ],
      dfuIndicatorsOptions: [
        { title: 'Patient has been informed of the risks of a diabetic foot ulcer', value: 'informed' },
        { title: 'Patient was unable to accept diabetic foot ulcer risk information', value: 'not accepted' },
      ],
      dfuIndicatorsNotAcceptedOptions: [
        { title: 'Patient has been informed of the risks of a diabetic foot ulcer <span style="color: orange">(N/A, patient was reported unable to accept footwear evaluation)</span>', value: 'informed', disabled: true },
        { title: 'Patient was unable to accept diabetic foot ulcer risk information ', value: 'not accepted' },
      ],
      footwearEvaluationOptions: [
        { title: 'A footwear exam was performed', value: 'exam performed' },
        { title: 'Patient not eligible candidate for footwear evaluation', value: 'not eligible' },
        { title: 'Patient was unable to accept diabetic footwear evaluation', value: 'not accepted' },
      ],
      footwearEvaluationNotAcceptedOptions: [
        { title: 'A footwear exam was performed <span style="color: orange">(N/A, patient was reported unable to accept DFU risk review)</span>', value: 'exam performed', disabled: true },
        { title: 'Patient not eligible candidate for footwear evaluation <span style="color: orange">(N/A, patient was reported unable to accept DFU risk review)</span>', value: 'not eligible', disabled: true },
        { title: 'Patient was unable to accept diabetic footwear evaluation', value: 'not accepted' },
      ],
      a1cLevelsOptions: [
        { title: 'HbA1c level > 9.0%', value: '> 9.0%' },
        { title: 'HbA1c level 7.0 - 9.0%', value: '7.0 - 9.0%' },
        { title: 'HbA1c level < 7.0%', value: '< 7.0%' },
        { title: 'HbA1c level not performed', value: 'not performed' },
      ],

      /* Disabled in SWC-838 (forced to not required, false) */
      dfuRiskRequired: false,
      footwearEvaluationRequired: false,
      a1cLevelRequired: false,
    }
  },
  computed: {
    dfuInvalid() {
      return this.dfu_risk === 'not accepted' && !this.dfu_risk_reason

      /* Disabled in SWC-838 (forced to not required)
      return this.dfuRiskRequired
        && (
          !this.dfu_risk
          || (this.dfu_risk === 'not accepted' && !this.dfu_risk_reason)
        )
      */
    },
    footwearInvalid() {
      return (this.footwear_evaluation === 'exam performed' && this.footwear_notable_issues.length < 1)
        || (this.footwear_evaluation === 'not accepted' && !this.footwear_evaluation_reason)

      /* Disabled in SWC-838 (forced to not required)
      return this.footwearEvaluationRequired
        && (
          !this.footwear_evaluation
          || (this.footwear_evaluation === 'exam performed' && !this.footwear_exam_reason)
          || (this.footwear_evaluation === 'not accepted' && !this.footwear_evaluation_reason)
          || this.$custom.isEmpty(this.footwear_notable_issues)
        )
      */
    },
    a1cInvalid() {
      return false

      /* Disabled in SWC-838 (forced to not required)
      return this.a1cLevelRequired && !this.a1c_level
      */
    },
    // Returns true if a1c is NOT applicable based on patient's age (<18 or >75).
    isA1cNotApplicable() {
      // A1C is only applicable to patients between the ages of 18 and 75, inclusive.
      return this.ptAge < 18 || this.ptAge > 75
    },
    ...mapGetters('encounters', { footwearNotableIssuesOptions: 'footwearNotableIssues' }),
  },
  watch: {
    dfu_risk(newValue) {
      this.$emit('update:dfu-risk', this.dfu_risk)

      // If value is changed to 'not accepted', it doesn't make sense for the footwear_evaluation to have a value other than 'not accepted'.
      if (newValue === 'not accepted' && this.footwear_evaluation !== 'not accepted') {
        // Clear the footwear_evaluation value
        this.footwear_evaluation = null
        this.footwearEvaluationChanged()
      }
    },
    dfu_risk_reason() {
      this.$emit('update:dfu-risk-reason', this.dfu_risk_reason)
    },
    footwear_evaluation(newValue) {
      this.$emit('update:footwear-evaluation', this.footwear_evaluation)

      // If value is changed to 'not accepted', it doesn't make sense for the dfu_risk to have a value other than 'not accepted'.
      if (newValue === 'not accepted' && this.dfu_risk !== 'not accepted') {
        // Clear the dfu_risk value
        this.dfu_risk = null
        this.dfuRiskChanged()
      }
    },
    footwear_evaluation_reason() {
      this.$emit('update:footwear-evaluation-reason', this.footwear_evaluation_reason)
    },
    footwear_notable_issues() {
      this.$emit('update:footwear-notable-issues', this.footwear_notable_issues)
    },
    footwear_exam_reason() {
      this.$emit('update:footwear-exam-reason', this.footwear_exam_reason)
    },
    a1c_level() {
      this.$emit('update:a1c-level', this.a1c_level)
    },
    highlightForm() {
      // Select the first tab with invalid fields
      if (this.highlightForm) {
        if (this.dfuInvalid) this.activeTab = 0
        else if (this.footwearInvalid) this.activeTab = 1
        else if (this.a1cInvalid) this.activeTab = 2
      }
    },
  },
  methods: {
    footwearEvaluationChanged() {
      this.footwear_evaluation_reason = null
      this.footwear_exam_reason = null
      this.footwear_notable_issues = []
    },
    dfuRiskChanged() {
      this.dfu_risk_reason = null
    },
  },
}
</script>
