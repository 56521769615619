<template>
  <NoteSection
    label="Active Wound Infection"
  >
    <checkbox
      v-model="section.elevated_temperature"
      label="Patient has elevated temperature related to wound, all other causes are ruled out"
      class="col-sm-12"
      hide-details
    ></checkbox>
    <checkbox
      v-model="section.positive_infection"
      label="Positive wound biopsy for infection"
      class="col-sm-12"
      hide-details
    ></checkbox>
    <checkbox
      v-model="section.inflammation_drainage"
      label="Severe inflammation or drainage from the wound"
      class="col-sm-12"
      hide-details
    ></checkbox>
    <text-area
      v-model="section.note"
      class="col-sm-6"
      :label="(section.elevated_temperature || section.positive_infection || section.inflammation_drainage)
        ? 'Active wound infection notes'
        : 'Not applicable. You may skip this section.'"
      :required="section.elevated_temperature || section.positive_infection || section.inflammation_drainage"
      :disabled="!section.elevated_temperature && !section.positive_infection && !section.inflammation_drainage"
      counter="1000"
      maxlength="1000"
    ></text-area>
    <text-area
      v-model="section.cbc"
      class="col-sm-6"
      :label="(section.elevated_temperature || section.positive_infection || section.inflammation_drainage)
        ? 'Complete blood count (CBC)'
        : 'Not applicable. You may skip this section.'"
      :required="section.elevated_temperature || section.positive_infection || section.inflammation_drainage"
      :disabled="!section.elevated_temperature && !section.positive_infection && !section.inflammation_drainage"
    ></text-area>
  </NoteSection>
</template>

<script>
const initialData = {
  elevated_temperature: null,
  positive_infection: null,
  inflammation_drainage: null,
  note: null,
  cbc: null,
}

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      section: {
        ...initialData,
        ...this.value,
      },
    }
  },
  computed: {
  },
  watch: {
    section: {
      deep: true,
      handler() {
        this.$emit('input', this.section)
      },
    },
  },
  methods: {
  },
}
</script>
