<template>
  <NoteSection
    label="General Appearance"
  >
    <check-boxes
      v-model="general_appearances"
      :items="generalAppearances"
      class="col-sm-12 mt--1"
      columns="3"
      hide-details
      radio-buttons
      required
      return-object
    ></check-boxes>
    <text-field
      v-if="general_appearances.some(x => x.title === 'Other')"
      v-model="general_appearances_other"
      class="col-sm-12"
      label="Enter other general appearance..."
      counter="800"
      maxlength="800"
      required
    ></text-field>
  </NoteSection>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Array,
      default: null,
    },
    generalAppearancesOther: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      general_appearances: [...this.value],
      general_appearances_other: this.generalAppearancesOther,
    }
  },
  computed: {
    ...mapGetters('encounters', ['generalAppearances']),
  },
  watch: {
    general_appearances() {
      this.$emit('input', this.general_appearances)
    },
    general_appearances_other() {
      this.$emit('update:general-appearances-other', this.general_appearances_other)
    },
  },
  methods: {
  },
}
</script>
