<template>
  <NoteSection
    label="Family Health History"
  >
    <radio-buttons
      v-model="family_history_contributory"
      :items="query"
      label="Contributory?"
      class="col-sm-12 mt--1"
      hide-details
      required
      row
    ></radio-buttons>
    <text-area
      v-model="family_history"
      class="col-sm-12"
      label="Enter family health history..."
      :required="family_history_contributory"
      counter="500"
      maxlength="500"
    ></text-area>
  </NoteSection>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    familyHistoryContributory: {
      type: [Boolean, Number],
      default: null,
    },
    familyHistory: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      family_history_contributory: this.familyHistoryContributory,
      family_history: this.familyHistory,
    }
  },
  computed: {
    ...mapGetters('baseData', ['query']),
  },
  watch: {
    family_history_contributory(newValue) {
      this.$emit('update:family-history-contributory', this.family_history_contributory)

      // Clear family health history text if user changed to non-contributory.
      if (!newValue) {
        this.family_history = null
      }
    },
    family_history() {
      this.$emit('update:family-history', this.family_history)
    },
  },
  methods: {
  },
}
</script>
