<template>
  <NoteSection
    label="Mental Status"
  >
    <select-box
      v-model="mental_status"
      :items="mentalStatuses"
      label="Mental Status"
      class="col-sm-12"
      required
    >
    </select-box>
  </NoteSection>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      mental_status: this.value,
    }
  },
  computed: {
    ...mapGetters('encounters', ['mentalStatuses']),
  },
  watch: {
    mental_status() {
      this.$emit('input', this.mental_status)
    },
  },
  methods: {
  },
}
</script>
