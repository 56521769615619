<template>
  <NoteSection
    label="Chief Complaint"
  >
    <text-area
      v-model="chief_complaint"
      class="col-sm-12"
      label="Enter chief complaint..."
      required
      ai
      counter="6600"
      maxlength="6600"
      word-count="4"
      @click:prepend="generateChiefComplaint"
      @input="$emit('input', chief_complaint)"
    ></text-area>
  </NoteSection>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    woundList: {
      type: Array,
      default: null,
    },
    treatmentStatus: {
      type: Boolean,
      default: false,
    },
    patientLastName: {
      type: String,
      default: null,
    },
    patientGender: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      chief_complaint: this.value,
    }
  },
  computed: {},
  watch: {},
  methods: {
    generateChiefComplaint() {
      if (!this.treatmentStatus) {
        this.$root.confirm({
          titleIconColor: 'error',
          title: 'Treatments not validated!',
          body: 'You must have at least one treatment for this encounter and all encounter treatments must be validated before generating automatic text.',
          cancel: false,
        })

        return
      }
      let chiefComplaint = ''
      const woundItems = []
      const dermItems = []
      const gTubeItems = []
      if (!this.$custom.isEmpty(this.woundList)) {
        this.woundList.forEach(wound => {
          if (wound.practice_type_id === 1) {
            woundItems.push(wound)
          } else if (wound.practice_type_id === 5) {
            gTubeItems.push(wound)
          } else if (wound.practice_type_id === 6) {
            dermItems.push(wound)
          }
        })
      }
      const woundLocations = []
      const dermLocations = []
      woundItems.forEach(wound => {
        if (wound.location !== null && wound.location?.location_text !== null) {
          woundLocations.push(wound.location?.location_text)
        }
      })
      dermItems.forEach(derm => {
        if (derm.location !== null && derm.location?.location_text !== null) {
          dermLocations.push(derm.location?.location_text)
        }
      })
      if (woundItems.length > 0 || dermItems.length > 0) {
        const greeting = `${this.patientGender === 'Male' ? 'Mr.' : 'Ms.'} ${this.patientLastName}'s`
        chiefComplaint = 'I '
        chiefComplaint += this.$custom.randomString(['was', 'have been'])
        chiefComplaint += this.$custom.randomString(['asked', 'consulted'])
        chiefComplaint += this.$custom.randomString(['', 'for my opinion'])
        chiefComplaint += this.$custom.randomString(['about', 'regarding', 'in regards to', 'with regards to'])
        chiefComplaint += this.$custom.randomString(['how to manage', 'managing', 'overseeing', 'evaluation and treatment of', 'evaluating and treating', 'review and treatment of', 'reviewing/treating'])
        chiefComplaint += this.$custom.randomString(["the patient's", "this patient's", greeting])
        chiefComplaint += this.$custom.toListCommaAnd(woundLocations, null, 'lc')
        if (woundItems.length > 0) {
          const woundText = woundItems.length > 1 ? 'wounds' : 'wound'
          chiefComplaint += this.$custom.randomString(['wound concerns', 'wound situation', woundText])
        }
        if (woundItems.length > 0 && dermItems.length > 0) {
          chiefComplaint += ' and '
        }
        if (dermLocations.length > 0) {
          chiefComplaint += this.$custom.toListCommaAnd(dermLocations, null, 'lc')
        }
        if (dermItems.length > 0) {
          const lesionText = dermItems.length > 1 ? 'lesions' : 'lesion'
          chiefComplaint += this.$custom.randomString(['lesion concerns', 'lesion situation', lesionText])
        }
        chiefComplaint += '.  '
        chiefComplaint += this.$custom.randomString(['Please see', 'See'])
        chiefComplaint += this.$custom.randomString(['the applicable sections below', 'the below information', 'the information cited below', 'below'])
        chiefComplaint += this.$custom.randomString(['for duration, etiology, severity, and progress', 'for severity, etiology, duration, and progress', 'for etiology, severity, duration, and progress', 'for severity, duration, etiology, and progress'])
        chiefComplaint += '.  '
      }
      if (gTubeItems.length > 0 && (woundItems.length > 0 || dermItems.length > 0)) {
        chiefComplaint += this.$custom.randomString(['Also to be addressed are concerns related to', 'Consideration will be given to concerns related to', 'An evaluation will also be made for the', 'Further evaluation will be made for concerns regarding', 'Further consideration will also be made for the'])
        gTubeItems.forEach(gTubeItem => {
          if (gTubeItem.treatment.procedure_id === 150) {
            chiefComplaint += 'G-tube-related issues'
          } else if ([300, 301].includes(gTubeItem.treatment.procedure_id)) {
            chiefComplaint += 'emergent replacement of a gastrostomy tube'
          } else if ([311, 312, 321, 322, 331, 332].includes(gTubeItem.treatment.procedure_id)) {
            chiefComplaint += 'replacement of a gastrostomy tube'
          } else {
            chiefComplaint += 'G-tube-related issues'
          }
        })
        chiefComplaint += '.  '
      }
      if (gTubeItems.length > 0 && woundItems.length === 0 && dermItems.length === 0) {
        gTubeItems.forEach(gTubeItem => {
          if (gTubeItem.treatment.procedure_id === 150) {
            chiefComplaint += 'G-tube-related issues'
          } else if ([300, 301].includes(gTubeItem.treatment.procedure_id)) {
            chiefComplaint += 'Emergent replacement of a gastrostomy tube'
          } else if ([311, 312, 321, 322, 331, 332].includes(gTubeItem.treatment.procedure_id)) {
            chiefComplaint += 'Replacement of a gastrostomy tube'
          } else {
            chiefComplaint += 'G-tube-related issues'
          }
        })
        chiefComplaint += '.  '
      }
      this.chief_complaint = this.$custom.clean(chiefComplaint, 'Chief Complaint')
      this.$emit('input', this.chief_complaint)
    },
  },
}
</script>
