<template>
  <NoteSection
    label="Change in Patient Health"
  >
    <select-box
      v-model="health_change"
      :items="healthChanges"
      label="Change in Patient Health"
      class="col-sm-12"
      required
    >
    </select-box>
    <text-area
      v-if="health_change === 'Other'"
      v-model="health_change_other"
      class="col-sm-12"
      label="Enter change in patient health..."
      counter="7000"
      maxlength="7000"
      required
    ></text-area>
  </NoteSection>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    healthChangeOther: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      health_change: this.value,
      health_change_other: this.healthChangeOther,
    }
  },
  computed: {
    ...mapGetters('encounters', ['healthChanges']),
  },
  watch: {
    health_change() {
      this.$emit('input', this.health_change)
    },
    health_change_other() {
      this.$emit('update:health-change-other', this.health_change_other)
    },
  },
  methods: {
  },
}
</script>
