<template>
  <v-card
    v-if="$route.query.id"
    :key="mounted"
    :class="highlightForm && 'highlight-required-fields'"
  >
    <div class="mb-4 sticky-header">
      <PatientInformation
        v-if="encounter.practice_type_id && patient"
        :title="computedTitle"
        :patient="patient"
        :visit-type="encounter.visit_type"
      ></PatientInformation>
    </div>

    <Attachments
      :is-signed="encounter.is_signed"
      :patient-id="patient.id"
      :encounter-attachments.sync="patient.all_attachments"
    ></Attachments>

    <v-card-actions
      v-if="!isQuickEntry"
      class="align-start pb-0"
    >
      <!-- validated -->
      <icon-value
        :icon="formValidated ? icons.mdiCheckDecagram : icons.mdiCloseCircle"
        :value="formValidated ? 'Validated' : 'Not Validated'"
        :color="formValidated ? 'success' : 'error'"
      ></icon-value>

      <v-spacer></v-spacer>

      <!-- signed -->
      <icon-value
        :icon="encounter.is_signed ? icons.mdiClipboardCheck : icons.mdiCloseCircle"
        :value="encounter.is_signed ? 'Signed' : 'Not Signed'"
        :color="encounter.is_signed ? 'success' : 'error'"
      ></icon-value>

      <v-spacer></v-spacer>

      <!-- synced -->
      <icon-value
        :icon="encounter.is_synced ? icons.mdiCloudCheckVariant : icons.mdiCloseCircle"
        :value="encounter.is_synced ? 'Synced' : 'Not Synced'"
        :color="encounter.is_synced ? 'success' : 'error'"
      ></icon-value>

      <v-spacer></v-spacer>

      <!-- review surgical note -->
      <btn
        v-if="formValidated"
        label="Review Note"
        :icon="icons.mdiEyeOutline"
        @click="viewSurgicalNote"
      ></btn>

      <!-- validate note -->
      <btn
        v-else
        label="Validate"
        color="warning"
        :icon="icons.mdiCheckDecagram"
        @click="validateEncounter"
      ></btn>
    </v-card-actions>

    <!-- *** INSURANCE AND AUTHORIZATION -->

    <InsuranceAuthorization
      :encounter="encounter"
      :highlight-form="highlightForm"
      :patient-id="patient.id"
      :comment-adds="true"
    >
    </InsuranceAuthorization>

    <NoteSection
      label="Encounter Comments"
      heading
    ></NoteSection>
    <Comments
      type="encounter"
      :encounter-id="encounter.id"
      :patient-id="patient.id"
    >
    </Comments>

    <v-form
      v-model="formValid"
      :class="encounter.is_signed ? 'form-disabled' : ''"
      :disabled="encounter.is_signed"
    >
      <EncounterDetails
        :visit-location.sync="encounter.visit_location"
        :visit-type="encounter.visit_type"
        :place-of-service-id.sync="encounter.place_of_service_id"
        :visit-date.sync="encounter.visit_date"
        :encounter="encounter"
        :patient-id="patient.id"
      ></EncounterDetails>

      <AdvanceCarePlan
        v-if="patientAge >= 65"
        v-model="encounter.advance_care_plan"
      ></AdvanceCarePlan>

      <!-- *** GENERAL ASSESSMENT *** -->
      <NoteSection
        label="General Assessment"
        heading
      ></NoteSection>

      <div v-if="!isQuickEntry">
        <Covid
          v-model="encounter.encounter_covid_assessment"
          :encounter-visit-date="encounter.visit_date"
        ></Covid>

        <HealthChange
          v-if="isFollowup"
          v-model="encounter.health_change"
          :health-change-other.sync="encounter.health_change_other"
        ></HealthChange>

        <RecentHospitalization
          :recently-hospitalized.sync="encounter.recently_hospitalized"
          :recently-hospitalized-related.sync="encounter.recently_hospitalized_related"
          :recently-hospitalized-date.sync="encounter.recently_hospitalized_date"
          :facility-id="encounter.place_of_service_id"
          :encounter-visit-date="encounter.visit_date"
        ></RecentHospitalization>

        <BMI
          :height-feet.sync="encounter.height_feet"
          :height-inches.sync="encounter.height_inches"
          :weight-pounds.sync="encounter.weight_pounds"
          :bmi.sync="encounter.bmi"
          :dob="patient.dob"
        ></BMI>
      </div>

      <AnticoagulantMattress
        v-if="isQuickEntry"
        :therapeutic-anticoagulant.sync="encounter.therapeutic_anticoagulant"
        :low-loss-mattress.sync="encounter.assessment_plan.low_loss_mattress"
        :is-signed="encounter.is_signed"
      ></AnticoagulantMattress>

      <GeneralAppearance
        v-model="encounter.general_appearances"
        :general-appearances-other.sync="encounter.general_appearances_other"
      ></GeneralAppearance>

      <VascularExam
        v-if="!isQuickEntry"
        v-model="encounter.encounter_pulse_assessment"
        :is-signed="encounter.is_signed"
      ></VascularExam>

      <!--      <AllergiesSensitivities-->
      <!--        :allergies-other.sync="encounter.allergies_other"-->
      <!--        :sensitivities-other.sync="encounter.sensitivities_other"-->
      <!--      ></AllergiesSensitivities>-->

      <Medications
        v-if="!isQuickEntry"
        v-model="encounter.medications"
        :therapeutic-anticoagulant.sync="encounter.therapeutic_anticoagulant"
        :medications-other.sync="encounter.medications_other"
        :is-signed="encounter.is_signed"
      ></Medications>

      <Anticoagulants
        v-if="!isQuickEntry"
        v-model="encounter.medications"
        :therapeutic-anticoagulant.sync="encounter.therapeutic_anticoagulant"
        :medications-other.sync="encounter.medications_other"
        :is-signed="encounter.is_signed"
      ></Anticoagulants>
      
      <MentalStatus v-model="encounter.mental_status"></MentalStatus>

      <ExaminationBodyAreas
        v-if="!isQuickEntry"
        v-model="encounter.body_areas"
        :body-areas-notes.sync="encounter.body_areas_notes"
        :is-signed="encounter.is_signed"
      ></ExaminationBodyAreas>

      <MobilityContinence
        :mobility.sync="encounter.mobility"
        :continence.sync="encounter.continence"
        :mobility-other.sync="encounter.mobility_other"
        :continence-other.sync="encounter.continence_other"
      ></MobilityContinence>

      <div v-if="!isQuickEntry">
        <!-- *** MEDICAL HISTORY *** -->
        <NoteSection
          label="Medical History"
          heading
        ></NoteSection>

        <PastMedicalHistory
          v-model="encounter.medical_histories"
          :medical-histories-other.sync="encounter.medical_histories_other"
          :neuropathy.sync="encounter.neuropathy"
          :neuropathy-type.sync="encounter.neuropathy_type"
          :neuropathy-severity.sync="encounter.neuropathy_severity"
          :is-signed="encounter.is_signed"
        ></PastMedicalHistory>

        <RiskFactors
          v-model="encounter.risk_factors"
          :risk-factors-other.sync="encounter.risk_factors_other"
          :is-signed="encounter.is_signed"
        ></RiskFactors>

        <!-- Action required warnings -->
        <alert
          v-if="dfuRiskRequired"
          class="mx-2"
        >
          DFU risk, annual DFU risk education due.
        </alert>

        <alert
          v-if="footwearEvaluationRequired"
          class="mx-2"
        >
          DFU risk, footwear evaluation is due.
        </alert>

        <alert
          v-if="a1cLevelRequired"
          class="mx-2"
        >
          Hemoglobin A1c level was not performed during the 12 month measurement period.
        </alert>

        <!-- Information -->
        <alert
          v-if="previousDfuRiskEncounter"
          color="info"
          class="mx-2"
        >
          <p>DFU risk education completed on {{ $date(previousDfuRiskEncounter.visit_date).format('MM/DD/YYYY') }}</p>
          <p class="mb-0">
            {{ previousDfuRiskEncounter.dfu_risk === 'informed'
              ? 'DFU Education administered'
              : 'DFU Education not administered to patient'
            }}
            <span v-if="previousDfuRiskEncounter.dfu_risk !== 'informed' && previousDfuRiskEncounter.dfu_risk_reason">
              - {{ previousDfuRiskEncounter.dfu_risk_reason }}
            </span>
          </p>
        </alert>

        <alert
          v-if="previousFootwearExamEncounter"
          color="info"
          class="mx-2"
        >
          <p>Diabetic footwear exam completed on {{ $date(previousFootwearExamEncounter.visit_date).format('MM/DD/YYYY') }}</p>
          <p :class="$custom.isEmpty(previousFootwearExamEncounter.footwear_notable_issues) ? 'mb-0' : ''">
            Result:
            {{ $custom.toProperCase(previousFootwearExamEncounter.footwear_evaluation) }}
            <span v-if="previousFootwearExamEncounter.footwear_evaluation_reason">
              - {{ previousFootwearExamEncounter.footwear_evaluation_reason }}
            </span>
            <span v-if="previousFootwearExamEncounter.footwear_exam_reason">
              - {{ previousFootwearExamEncounter.footwear_exam_reason }}
            </span>
          </p>
          <p
            v-if="!$custom.isEmpty(previousFootwearExamEncounter.footwear_notable_issues)"
            class="mb-0"
          >
            Patient has the following issues:
            {{ $custom.toListCommaAnd(previousFootwearExamEncounter.footwear_notable_issues) }}
          </p>
        </alert>

        <alert
          v-if="previousA1cLevelEncounter"
          color="info"
          class="mx-2"
        >
          <p>A1c results documented on {{ $date(previousA1cLevelEncounter.visit_date).format('MM/DD/YYYY') }}</p>
          <p class="mb-0">
            Most recent hemoglobin A1c result: {{ previousA1cLevelEncounter.a1c_level }}
          </p>
        </alert>

        <DiabeticRiskReview
          v-if="isDiabeticRisk && (!previousDfuRiskEncounter || !previousFootwearExamEncounter || !previousA1cLevelEncounter)"
          :key="diabeticRiskIndex"
          :dfu-risk.sync="encounter.dfu_risk"
          :dfu-risk-reason.sync="encounter.dfu_risk_reason"
          :footwear-evaluation.sync="encounter.footwear_evaluation"
          :footwear-evaluation-reason.sync="encounter.footwear_evaluation_reason"
          :footwear-notable-issues.sync="encounter.footwear_notable_issues"
          :footwear-exam-reason.sync="encounter.footwear_exam_reason"
          :a1c-level.sync="encounter.a1c_level"
          :highlight-form="highlightForm"
          :dfu-risk-required="dfuRiskRequired"
          :footwear-evaluation-required="footwearEvaluationRequired"
          :a1c-level-required="a1cLevelRequired"
          :previous-dfu-risk-encounter="previousDfuRiskEncounter !== false ? previousDfuRiskEncounter : null"
          :previous-footwear-exam-encounter="previousFootwearExamEncounter !== false ? previousFootwearExamEncounter : null"
          :previous-a1c-level-encounter="previousA1cLevelEncounter !== false ? previousA1cLevelEncounter : null"
          :pt-age="this.$custom.ageInYears(patient.dob)"
        ></DiabeticRiskReview>

        <SocialHistory
          :social-history-contributory.sync="encounter.social_history_contributory"
          :social-histories.sync="encounter.social_histories"
          :social-history-other.sync="encounter.social_history_other"
        ></SocialHistory>

        <FamilyHeathHistory
          :family-history-contributory.sync="encounter.family_history_contributory"
          :family-history.sync="encounter.family_history"
        ></FamilyHeathHistory>
      </div>

      <!-- *** WOUND DETAIL *** -->
      <NoteSection
        label="Wound Detail"
        heading
      ></NoteSection>

      <Wounds
        v-model="patient.wounds"
        :practice-type-id="encounter.practice_type_id"
        :patient="patient"
        :is-signed="encounter.is_signed"
        :is-circulation-compromised="isCirculationCompromised"
        :encounter-id="encounter.id"
        :highlight-form="highlightForm"
        :visit-date="encounter.visit_date"
        :place-of-service-id="encounter.place_of_service_id"
        :wound-treatment-updated.sync="woundTreatmentUpdated"
        :encounter-attachments="encounter.encounter_attachments"
        :therapeutic-anticoagulant="encounter.therapeutic_anticoagulant"
        :visit-location="encounter.visit_location"
        :quick-entry="encounter.is_quick_entry"
      ></Wounds>

      <div v-if="!isQuickEntry">
        <ActiveWoundInfection
          v-model="encounter.encounter_active_wound_infection"
        ></ActiveWoundInfection>

        <!-- *** VITALS *** -->
        <NoteSection
          label="Vitals"
          heading
        ></NoteSection>

        <Vitals
          :temperature.sync="encounter.temperature"
          :temperature-scale.sync="encounter.temperature_scale"
          :heart-rate.sync="encounter.heart_rate"
          :bp-systolic.sync="encounter.bp_systolic"
          :bp-diastolic.sync="encounter.bp_diastolic"
          :respiratory-rate.sync="encounter.respiratory_rate"
          :require-vitals="requireVitalsComputed"
        ></Vitals>

        <!-- *** ASSESSMENT & PLAN *** -->
        <NoteSection
          label="Assessment &amp; Plan"
          heading
        ></NoteSection>

        <AssessmentAndPlan
          v-model="encounter.assessment_plan"
          :has-wound-treatment-type="hasWoundTreatmentType"
          :highlight-form="highlightForm"
          :wound-list="woundsWithEncounterTreatment"
          :treatment-status="treatmentsStatus"
          :patient="patient"
          :encounter="encounter"
        ></AssessmentAndPlan>
      </div>

      <!-- *** VISIT SUMMARY *** -->
      <NoteSection
        label="Visit Summary"
        heading
      ></NoteSection>

      <Labs
        v-model="encounter.labs"
        :labs-other.sync="encounter.labs_other"
        :labs-order-date.sync="encounter.labs_order_date"
      ></Labs>

      <div v-if="!isQuickEntry">
        <VisitReason
          v-model="encounter.visit_reason"
          :wound-list="woundsWithEncounterTreatment"
          :treatment-status="treatmentsStatus"
        ></VisitReason>

        <ChiefComplaint
          v-if="!isFollowup"
          v-model="encounter.chief_complaint"
          :wound-list="woundsWithEncounterTreatment"
          :treatment-status="treatmentsStatus"
          :patient-last-name="patient.last_name"
          :patient-gender="patient.gender"
        ></ChiefComplaint>

        <PresentIllnessHistory
          v-if="!isFollowup"
          v-model="encounter.present_illness_history"
          :medical-history-list="encounter.medical_histories"
          :medical-history-other="encounter.medical_histories_other"
          :risk-factor-list="encounter.risk_factors"
          :risk-factor-other="encounter.risk_factors_other"
          :wound-list="woundsWithEncounterTreatment"
          :treatment-status="treatmentsStatus"
          :patient-dob="patient.dob"
          :patient-gender="patient.gender"
          :covid-assessment="encounter.encounter_covid_assessment"
        ></PresentIllnessHistory>

        <SystemsReview
          v-model="encounter.systems_review"
          :medical-history-list="encounter.medical_histories"
          :medical-history-other="encounter.medical_histories_other"
          :risk-factor-list="encounter.risk_factors"
          :risk-factor-other="encounter.risk_factors_other"
          :neuropathy="encounter.neuropathy"
          :neuropathy-type="encounter.neuropathy_type"
          :neuropathy-severity="encounter.neuropathy_severity"
        ></SystemsReview>
      </div>

      <v-card-actions class="sticky-bottom">
        <!-- cancel -->
        <btn
          v-if="!encounter.is_signed && encounterChanged"
          label="Cancel"
          color="secondary"
          :icon="icons.mdiCancel"
          @click="cancelEncounter"
        ></btn>

        <!-- close -->
        <btn
          v-else
          label="Close"
          color="secondary"
          :icon="icons.mdiClose"
          @click="exit()"
        ></btn>

        <!-- delete note -->
        <btn
          v-if="!encounter.is_signed"
          label="Delete"
          :icon="icons.mdiTrashCan"
          :disabled="encounter.is_signed"
          color="error"
          @click="deleteEncounter"
        ></btn>

        <v-spacer></v-spacer>

        <!-- save note -->
        <btn
          v-if="formValidated"
          label="Save Note"
          :icon="icons.mdiContentSave"
          color="success"
          :disabled="invalidVisitDate"
          @click="saveEncounter"
        ></btn>

        <!-- save draft -->
        <btn
          v-else
          label="Save Draft"
          :icon="icons.mdiContentSaveEdit"
          color="success"
          :disabled="invalidVisitDate"
          @click="saveDraft"
        ></btn>

        <!-- review surgical note -->
        <btn
          v-if="formValidated && !isQuickEntry"
          label="Review Note"
          :icon="icons.mdiEyeOutline"
          @click="viewSurgicalNote"
        ></btn>

        <!-- validate note -->
        <btn
          v-else-if="!formValidated"
          label="Validate"
          color="warning"
          :icon="icons.mdiCheckDecagram"
          :disabled="invalidVisitDate"
          @click="validateEncounter"
        ></btn>
      </v-card-actions>
    </v-form>
    <!-- dump object -->
    <div
      v-if="$authUser.user() && $authUser.user().is_superuser"
      class="btn-top-center"
    >
      <btn
        label="Dump"
        :icon="icons.mdiDownload"
        x-small
        color="secondary"
        @click="$store.commit('encounters/updateEncounter', { encounter, patient });
                $router.push({ name: 'dump-encounter', query: { id: encounter.id } })"
      ></btn>
    </div>
  </v-card>
</template>

<script>

// Mixins
import Encounter from '@/mixins/Encounter'
import EncounterDetail from '@/mixins/EncounterDetail'
import WoundCare from '@/mixins/practice-types/WoundCare'

// Insurance & Authorization
import InsuranceAuthorization from '@/components/notes/insurance/InsuranceAuthorization.vue'

// Encounter
import Comments from '@/components/features/Comments.vue'
import AdvanceCarePlan from '@/components/notes/encounter/AdvanceCarePlan.vue'
import EncounterDetails from '@/components/notes/encounter/EncounterDetails.vue'
import PatientInformation from '@/components/notes/encounter/PatientInformation.vue'

// General Assessment
import AllergiesSensitivities from '@/components/notes/general-assessment/AllergiesSensitivities.vue'
import AnticoagulantMattress from '@/components/notes/general-assessment/AnticoagulantMattress.vue'
import BMI from '@/components/notes/general-assessment/BMI.vue'
import Covid from '@/components/notes/general-assessment/Covid.vue'
import ExaminationBodyAreas from '@/components/notes/general-assessment/ExaminationBodyAreas.vue'
import GeneralAppearance from '@/components/notes/general-assessment/GeneralAppearance.vue'
import HealthChange from '@/components/notes/general-assessment/HealthChange.vue'
import Medications from '@/components/notes/general-assessment/Medications.vue'
import Anticoagulants from '@/components/notes/general-assessment/Anticoagulants.vue'
import MentalStatus from '@/components/notes/general-assessment/MentalStatus.vue'
import MobilityContinence from '@/components/notes/general-assessment/MobilityContinence.vue'
import RecentHospitalization from '@/components/notes/general-assessment/RecentHospitalization.vue'
import VascularExam from '@/components/notes/general-assessment/VascularExam.vue'

// Medical History
import DiabeticRiskReview from '@/components/notes/medical-history/DiabeticRiskReview.vue'
import FamilyHeathHistory from '@/components/notes/medical-history/FamilyHeathHistory.vue'
import PastMedicalHistory from '@/components/notes/medical-history/PastMedicalHistory.vue'
import RiskFactors from '@/components/notes/medical-history/RiskFactors.vue'
import SocialHistory from '@/components/notes/medical-history/SocialHistory.vue'

// Wound Detail
import Vitals from '@/components/notes/general-assessment/Vitals.vue'
import ActiveWoundInfection from '@/components/notes/wound-detail/ActiveWoundInfection.vue'
import AssessmentAndPlan from '@/components/notes/wound-detail/AssessmentAndPlan.vue'
import Wounds from '@/components/notes/wound-detail/Wounds.vue'

// Visit Summary
import ChiefComplaint from '@/components/notes/visit-summary/ChiefComplaint.vue'
import Labs from '@/components/notes/visit-summary/Labs.vue'
import PresentIllnessHistory from '@/components/notes/visit-summary/PresentIllnessHistory.vue'
import SystemsReview from '@/components/notes/visit-summary/SystemsReview.vue'
import VisitReason from '@/components/notes/visit-summary/VisitReason.vue'

export default {
  components: {
    // Insurance & Authorization
    InsuranceAuthorization,

    // Encounter
    Comments,
    PatientInformation,
    EncounterDetails,
    AdvanceCarePlan,

    // General Assessment
    Covid,
    HealthChange,
    RecentHospitalization,
    BMI,
    AnticoagulantMattress,
    GeneralAppearance,
    VascularExam,
    AllergiesSensitivities,
    Medications,
    Anticoagulants,
    MentalStatus,
    ExaminationBodyAreas,
    MobilityContinence,

    // Medical History
    PastMedicalHistory,
    RiskFactors,
    DiabeticRiskReview,
    SocialHistory,
    FamilyHeathHistory,

    // Wound Detail
    Wounds,
    ActiveWoundInfection,
    Vitals,
    AssessmentAndPlan,

    // Visit Summary
    Labs,
    VisitReason,
    ChiefComplaint,
    PresentIllnessHistory,
    SystemsReview,
  },
  mixins: [WoundCare, Encounter, EncounterDetail],
  data() {
    return {
      isDiabeticRisk: false,
      diabeticRiskIndex: 0,
      previousFacilityId: null,
      previousVisitDate: null,
      woundsWithEncounterTreatment: [],
      treatmentsStatus: false,
      requireVitals: false,
    }
  },
  computed: {
    isQuickEntry() {
      if (!this.mounted) return false

      return (this.$route.query.quick || this.$route.query.quick === 'true') && this.encounter.is_quick_entry
    },
    computedTitle() {
      return this.isQuickEntry ? 'Wound Care Quick Entry' : 'Wound Care Encounter Detail'
    },
    isCirculationCompromised() {
      return this.encounter.medical_histories.some(x => Number(x.id) === 12) // Diabetes Type 1
        || this.encounter.medical_histories.some(x => Number(x.id) === 13) // Diabetes Type 2
        || this.encounter.medical_histories.some(x => Number(x.id) === 25) // PAD
        || this.encounter.medical_histories.some(x => Number(x.id) === 28) // PVD
        || this.encounter.medical_histories.some(x => Number(x.id) === 35) // VSD
    },
    previousEncountersLastYear() {
      // This prevents doing the previousEncounters() query till the page is mounted
      if (!this.mounted) return []

      return this.$store.getters['encounters/previousEncounters'](this.patient.id, this.encounter.practice_type_id, this.encounter.id, 365)
    },
    dfuRiskRequired() {
      return this.isDiabeticRisk
        && this.previousEncountersLastYear.findIndex(x => !this.$custom.isEmpty(x.dfu_risk)) === -1
    },
    footwearEvaluationRequired() {
      return this.isDiabeticRisk
        && this.previousEncountersLastYear.findIndex(x => !this.$custom.isEmpty(x.footwear_evaluation)) === -1
    },
    a1cLevelRequired() {
      return this.isDiabeticRisk
        && this.patientAge >= 18
        && this.patientAge <= 75
        && this.previousEncountersLastYear.findIndex(x => !this.$custom.isEmpty(x.a1c_level)) === -1
    },
    previousDfuRiskEncounter() {
      const index = this.previousEncountersLastYear.findIndex(x => !this.$custom.isEmpty(x.dfu_risk))

      return index !== -1 && this.isDiabeticRisk ? this.previousEncountersLastYear[index] : false
    },
    previousFootwearExamEncounter() {
      const index = this.previousEncountersLastYear.findIndex(x => !this.$custom.isEmpty(x.footwear_evaluation))

      return index !== -1 && this.isDiabeticRisk ? this.previousEncountersLastYear[index] : false
    },
    previousA1cLevelEncounter() {
      const index = this.previousEncountersLastYear.findIndex(x => !this.$custom.isEmpty(x.a1c_level))

      return index !== -1 && this.isDiabeticRisk ? this.previousEncountersLastYear[index] : false
    },
    requireVitalsComputed() {
      return this.requireVitals
        || this.encounter?.assessment_plan?.is_on_antibiotics
        || this.encounter?.encounter_active_wound_infection?.elevated_temperature
        || this.encounter?.encounter_active_wound_infection?.positive_infection
        || this.encounter?.encounter_active_wound_infection?.inflammation_drainage
    },
  },
  watch: {
    'encounter.medical_histories': {
      deep: true,
      handler() {
        this.isDiabeticRisk = this.updateDiabeticRisk()
      },
    },
    'encounter.medical_histories_other': {
      deep: true,
      handler() {
        this.isDiabeticRisk = this.updateDiabeticRisk()
      },
    },
    'encounter.risk_factors': {
      deep: true,
      handler() {
        this.isDiabeticRisk = this.updateDiabeticRisk()
      },
    },
    'encounter.risk_factors_other': {
      deep: true,
      handler() {
        this.isDiabeticRisk = this.updateDiabeticRisk()
      },
    },
    'encounter.place_of_service_id': {
      handler() {
        if (this.mounted) {
          if (this.encounter.place_of_service_id !== this.previousFacilityId) {
            this.previousFacilityId = this.encounter.place_of_service_id
            this.updateTreatmentsFacility()
          }
        } else {
          this.previousFacilityId = this.encounter.place_of_service_id
        }
      },
    },
    'encounter.visit_date': {
      handler() {
        if (this.mounted) {
          if (this.encounter.visit_date !== this.previousVisitDate) {
            // Only show the requester if there's data to clear
            if (this.encounter.dfu_risk || this.encounter.footwear_evaluation || this.encounter.a1c_level) {
              this.$root.confirm({
                title: 'Change Visit Date?',
                body: 'Diabetic risk review values will be cleared by changing the visit date. Are you sure you wish to change the visit date?',
                cancel: 'No',
                confirm: 'Yes',
              }).then(result => {
                if (result) {
                  // Visit date change accepted: Reset values, re-render component and update the treatment's visit date
                  this.encounter.dfu_risk = null
                  this.encounter.dfu_risk_reason = null
                  this.encounter.footwear_evaluation = null
                  this.encounter.footwear_evaluation_reason = null
                  this.encounter.footwear_exam_reason = null
                  this.encounter.footwear_notable_issues = []
                  this.encounter.a1c_level = null
                  this.previousVisitDate = this.encounter.visit_date
                  this.diabeticRiskIndex += 1
                  this.updateTreatmentsVisitDate()
                } else {
                  // Change the visit date back to the previous value
                  this.encounter.visit_date = this.previousVisitDate
                }
              })
            } else {
              // Nothing to clear, so no need for a requester, just update the treatment's visit date
              this.previousVisitDate = this.encounter.visit_date
              this.updateTreatmentsVisitDate()
            }
          }
        } else {
          this.previousVisitDate = this.encounter.visit_date
        }
      },
    },
    woundTreatmentUpdated() {
      const encounterSaved = this.woundTreatmentUpdated
      this.woundTreatmentUpdated = false
      if (!this.encounter.is_signed) {
        this.encounter.is_validated = false
      }
      this.updateConditions()
      if (encounterSaved) this.setEncounterTreatments()
      this.encounterChanged = true
      this.encounter.is_validated = false
      this.encounter.is_synced = false
      if (encounterSaved) this.$store.commit('encounters/updateEncounter', { encounter: this.encounter, patient: this.patient })
    },
    'encounter.visit_location': {
      handler() {
        if (this.mounted) {
          // Check for validated wounds treatments with procedures.
          const woundsWithValidatedTreatmentAndProc = []
          this.patient.wounds.forEach(wound => {
            const treatment = wound.treatments.filter(x => x.encounter_id === this.encounter.id && x.procedure_id !== 999 && x.is_validated === true)
            if (treatment.length) {
              const newWound = { ...wound }
              delete newWound.treatments
              newWound.treatment = { ...treatment[0] }
              woundsWithValidatedTreatmentAndProc.push(newWound)
            }
          })

          // Only show the prompt if user is changing to telemedicine and there are validated treatments with procedures.
          if (woundsWithValidatedTreatmentAndProc.length > 0 && this.encounter.visit_location === 'Telemedicine') {
            this.$root.confirm({
              title: 'Change Visit Location to Telemedicine?',
              body: 'You have validated treatments with procedures. '
                + 'Changing this visit to telemedicine will invalidate those treatments, change their selected procedure to "none", '
                + 'and clear the text within the operative/procedural note. Do you want to proceed?',
              cancel: 'Cancel',
              confirm: 'Change to telemedicine',
            }).then(result => {
              if (result) {
                // Change to telemedicine confirmed: Invalidate treatments with procedures.
                this.invalidateTreatmentsForTelemed()
              } else {
                // Change the visit location back to in-person.
                this.encounter.visit_location = 'In-person'
              }
            })
          }
        }
      },
    },
  },
  mounted() {
    this.updateConditions()
    this.updateDiabeticRisk()
    this.setEncounterTreatments()
  },
  methods: {
    setEncounterTreatments() {
      /*
        Sets this.woundsWithEncounterTreatment which includes just wound treatments
        for this encounter which have not been deleted and are validated. Used
        in the AI text generation to simplify the wound locations and treatments
        for this encounter only.
      */
      const woundsWithEncounterTreatment = []
      this.patient.wounds.forEach(wound => {
        const treatment = wound.treatments.filter(x => x.encounter_id === this.encounter.id && x.deleted !== true && x.is_validated === true)
        if (treatment.length) {
          const newWound = { ...wound }
          delete newWound.treatments
          newWound.treatment = { ...treatment[0] }
          woundsWithEncounterTreatment.push(newWound)
        }
      })
      this.woundsWithEncounterTreatment = woundsWithEncounterTreatment

      /*
        Sets this.treatmentsStatus boolean if there's at least one treatment
        for this encounter and all started treatments are validated. Used in
        the AI text generation to verify there's enough validated treatment
        data to generate the AI text.
      */
      const treatmentsStatus = this.patient.wounds
        .map(x => x.treatments
          .filter(y => y.encounter_id === this.encounter.id && y.deleted !== true))
        .filter(x => x.length !== 0)
        .map(x => x
          .some(y => y.is_validated === true))
      this.treatmentsStatus = treatmentsStatus.length > 0 && treatmentsStatus.every(x => x === true)
    },
    deleteEncounter() {
      let isNewTreatment = false
      this.patient.wounds.forEach(wound => {
        if (wound.treatments.filter(x => x.deleted !== true).some(x => x.encounter_id === this.encounter.id)) {
          isNewTreatment = true
        }
      })
      if (isNewTreatment) {
        this.$root.confirm({
          titleIconColor: 'error',
          title: 'Can\'t Delete Encounter!',
          subTitle: 'Can\'t delete encounter because new treatments exist',
          body: 'If you wish to delete this encounter, you\'ll need to first delete all treatments for this encounter.',
          cancel: false,
        })
      } else {
        this.$root.confirm({
          title: 'Delete Encounter Note?',
          subTitle: 'Warning: This operation cannot be undone!',
          body: 'Are you sure you wish to delete this encounter note?',
          confirm: 'Delete Note',
          confirmIcon: this.icons.mdiTrashCan,
          confirmColor: 'error',
        }).then(result => {
          if (result) {
            if (this.encounter.can_be_deleted) {
              this.$store.commit('encounters/deleteEncounter', this.encounter)
            } else {
              this.encounter.deleted = true
              this.encounter.is_synced = false

              // Delete treatments marked for deletion for this encounter (the database will automatically delete these treatments)
              this.patient.wounds.forEach((wound, woundIndex) => {
                wound.treatments.forEach((treatment, treatmentIndex) => {
                  if (treatment.deleted === true && treatment.encounter_id === this.encounter.id) {
                    this.patient.wounds[woundIndex].treatments.splice(treatmentIndex, 1)
                  }
                })
              })

              // As encounter is being deleted, we need to remove the encounter ID from any wound location which was noted as being created by this encounter
              this.patient.wounds.forEach((wound, woundIndex) => {
                if (this.encounter.id === wound.encounter_id) {
                  this.patient.wounds[woundIndex].encounter_id = null
                }
              })

              this.$store.commit('encounters/updateEncounter', { encounter: this.encounter, patient: this.patient })
            }
            this.$store.dispatch('notify', { value: 'Encounter note deleted successfully.' })
            this.encounterChanged = false
            this.exit()
          }
        })
      }
    },
    updateConditions() {
      this.requireVitals = false
      this.hasWoundTreatmentType = false
      if (!Array.isArray(this.patient.wounds)) {
        this.patient.wounds = []
      } else {
        this.patient.wounds.forEach(wound => {
          if (Number(wound.practice_type_id) === 1) {
            wound.treatments.forEach(treatment => {
              if (treatment.encounter_id === this.encounter.id && !treatment.deleted) {
                // Encounter has "wound" type treatment
                this.hasWoundTreatmentType = true

                // Check for signs of infection
                const isInfected = treatment.wound_treatment
                  && treatment.wound_treatment.infection_signs
                  && treatment.wound_treatment.infection_signs.some(x => x.title !== 'None')

                // Require vitals if signs of infection are present
                this.requireVitals = isInfected
              }
            })
          }
        })
      }
    },
    updateDiabeticRisk() {
      return this.$custom.itemTitleContains(this.encounter.medical_histories, 'diabetes')
        || this.$custom.strContains(this.encounter.medical_histories_other, 'diabetes')
        || this.$custom.itemTitleContains(this.encounter.risk_factors, 'diabetes')
        || this.$custom.strContains(this.encounter.risk_factors_other, 'diabetes')
    },
    updateTreatmentsFacility() {
      this.patient.wounds.forEach(wound => {
        const treatment = wound.treatments.filter(x => x.encounter_id === this.encounter.id && x.deleted !== true)
        if (treatment.length === 1) {
          treatment[0].place_of_service_id = this.encounter.place_of_service_id
        }
      })
    },
    updateTreatmentsVisitDate() {
      this.patient.wounds.forEach(wound => {
        const treatment = wound.treatments.filter(x => x.encounter_id === this.encounter.id && x.deleted !== true)
        if (treatment.length === 1) {
          treatment[0].encounter.visit_date = this.encounter.visit_date
        }
      })
    },
    invalidateTreatmentsForTelemed() {
      this.patient.wounds.forEach(wound => {
        // Invalidate previously validated wound treatments with procedures other than none.
        const treatment = wound.treatments.filter(x => x.encounter_id === this.encounter.id && x.procedure_id !== 999 && x.is_validated === true)
        if (treatment.length === 1) {
          treatment[0].is_validated = false
        }
      })
    },
  },
}
</script>
