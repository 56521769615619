<template>
  <NoteSection>
    <v-tabs
      v-model="activeTab"
      fixed-tabs
      color="success"
      class="col-sm-12 mb-5"
    >
      <v-tab>{{ operativeNoteLabel }}</v-tab>
      <v-tab :disabled="!isFollowUpTreatment">
        History
      </v-tab>

      <!-- note -->
      <v-tab-item eager>
        <text-area
          v-model="operative_note"
          :disabled="procedureId === 999"
          :label="procedureId === 999 ? operativeNoteLabelDisabled : operativeNoteLabel"
          :required="required"
          :rules="required && operative_note ? 'minWordCount(8)' : ''"
          class="col-sm-12"
          counter="8300"
          maxlength="8300"
          :rows="5"
          :wizard="isWoundTreatment"
          clear-warning
          @click:prepend="operativeNoteWizardModal = true"
        ></text-area>
      </v-tab-item>

      <!-- history -->
      <v-tab-item
        v-if="isFollowUpTreatment"
        eager
      >
        <div class="operative-note-history mb-3">
          <div
            v-for="treatment in wound_treatments"
            :key="treatment.id"
          >
            <v-row v-if="treatment.id !== treatmentId && !treatment.deleted">
              <label class="col-sm-2">
                {{ $date(treatment.encounter.visit_date).format('MM/DD/YYYY') }}
              </label>
              <div class="col-sm-10">
                {{ treatment.operative_note }}
              </div>
            </v-row>
          </div>
        </div>
      </v-tab-item>
    </v-tabs>

    <!-- operative note assistant wizard modal -->
    <OperativeNoteWizard
      v-if="isWoundTreatment"
      v-model="operative_note_wizard"
      :wound-treatment.sync="woundTreatment"
      :treatment-id="treatmentId"
      :procedure-id="procedureId"
      :modal-state.sync="operativeNoteWizardModal"
    ></OperativeNoteWizard>
  </NoteSection>
</template>

<script>
/* eslint-disable no-eval */
import OperativeNoteWizard from '@/components/notes/wound-detail/treatments/sections/OperativeNoteWizard.vue'
import { mapGetters } from 'vuex'

export default {
  components: { OperativeNoteWizard },
  props: {
    value: {
      type: String,
      default: null,
    },
    treatments: {
      type: Array,
      default: null,
    },
    treatmentId: {
      type: String,
      default: null,
    },
    highlightForm: {
      type: Boolean,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    operativeNoteWizard: {
      type: Object,
      default: null,
    },
    woundTreatment: {
      type: Object,
      default: null,
    },
    isWoundTreatment: {
      type: Boolean,
      default: false,
    },
    patient: {
      type: Object,
      default: null,
    },
    procedureId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      activeTab: 0,
      operative_note: this.value,
      wound_treatments: this.treatments.filter(x => x.deleted !== true).sort((a, b) => (a.encounter.visit_date < b.encounter.visit_date && 1) || -1),
      operativeNoteWizardModal: false,
      operative_note_wizard: this.operativeNoteWizard,
    }
  },
  computed: {
    ...mapGetters('encounters', [
      'opNoteBloodLosses',
      'opNoteCleansings',
      'opNoteHemostases',
      'opNoteIncisionTypes',
      'opNoteInstruments',
      'opNotePainManagements',
      'opNotePositionings',
      'opNoteVisualizedBones',
      'opNoteVisualizedMuscles',
      'dressings',
    ]),
    isFollowUpTreatment() {
      return this.wound_treatments ? this.wound_treatments.length > 1 : false
    },

    // Use procedural note if wound type is not wound.
    operativeNoteLabel() {
      return this.isWoundTreatment ? 'Operative Note' : 'Procedural Note'
    },
    operativeNoteLabelDisabled() {
      return 'Not applicable if selected procedure is "None".'
    },
  },
  watch: {
    /* No idea why this was added, doesn't appear to be required
    value: {
      deep: true,
      handler() {
        this.operative_note = this.value
        this.activeTab = 0
      },
    },
    */
    procedureId: {
      handler(newValue) {
        // Clear the op note if procedure is set to "none".
        if (newValue === 999) {
          this.operative_note = null
        }
      },
    },
    operative_note() {
      this.$emit('input', this.operative_note)
    },
    operativeNoteWizardModal() {
      if (!this.operativeNoteWizardModal) {
        let operativeNoteDescription = ''
        if (this.$custom.isEmpty(this.operative_note_wizard)) this.operative_note_wizard = {}

        // parts 1 and 2
        // conversions
        const consentObtainConversion = [
          { title: 'Family', conversion: 'the patient\'s family' },
          { title: 'Patient conservator', conversion: 'the patient conservator' },
          { title: 'Patient spouse', conversion: 'the patient\'s spouse' },
          { title: 'Patient', conversion: 'the patient' },
          { title: 'Two doctors', conversion: 'two doctors' },
        ]

        let position = this.opNotePositionings.findIndex(
          x => x.id === this.operative_note_wizard.positioning_id,
        )
        position = position !== -1 ? this.opNotePositionings[position].title.toLowerCase() : ''

        const positionNoun = position === 'in wheelchair'
          ? 'wheelchair'
          : position
        const positionVerb = (position === 'in wheelchair'
          ? 'seated'
          : this.$custom.randomString(['placed', 'situated', 'oriented']))
        const positionAOrAn = ['a', 'e', 'i', 'o', 'u'].includes(positionNoun.charAt(0)) ? 'an' : 'a'
        if (this.operative_note_wizard.consent_selected || this.operative_note_wizard.consent_text) {

          const consentObtain =
            !this.operative_note_wizard.consent_text
              // If no consent text, return consent selected conversion
              ? consentObtainConversion[consentObtainConversion.findIndex(
                  x => x.title === this.operative_note_wizard.consent_selected,
                )].conversion
              // Consent text found
              // Checks if consent selected is falsy
              : !this.operative_note_wizard.consent_selected
              // If no value was selected for consent selected, return consent text
              ? this.operative_note_wizard.consent_text
              // Consent selected found
              // Checks if consent text includes consent selected
              : consentObtainConversion.some(
                  x => this.operative_note_wizard.consent_text.includes(x.title),
                )
              // Replace consent selected string within consent text, with consent selected conversion
              ? this.operative_note_wizard.consent_text.replace(
                  this.operative_note_wizard.consent_selected,
                  consentObtainConversion.find(x => this.operative_note_wizard.consent_text.includes(x.title)).conversion
                )
              // Append consent text to consent selected conversion
              : consentObtainConversion[consentObtainConversion.findIndex(
                  x => x.title === this.operative_note_wizard.consent_selected,
                )].conversion.concat(' ', this.operative_note_wizard.consent_text)


          const positionPronoun = this.$custom.randomString([
            (this.patient.gender === 'Male' ? 'he was' : 'she was'),
            this.fieldContains(consentObtain, 'patient') ? 'the patient was' : 'they were',

          ])
          if (this.operative_note_wizard.positioning_id) {
            operativeNoteDescription += `After consent was obtained from ${consentObtain}, `
            operativeNoteDescription += `${positionPronoun} ${positionVerb} in ${positionAOrAn} ${positionNoun} position.  `
          } else {
            operativeNoteDescription += `Consent was obtained from ${consentObtain}.  `
          }
        } else if (this.operative_note_wizard.consent_selected === null) {
          if (this.operative_note_wizard.positioning_id) {
            operativeNoteDescription += `The patient was ${positionVerb} in ${positionAOrAn} ${positionNoun} position.  `
          }
        }

        // part 3
        if (this.operative_note_wizard.cleansing_id || this.operative_note_wizard.cleansing_2_id) {
          const cleansings = []
          if (this.operative_note_wizard.cleansing_id) {
            cleansings.push(this.opNoteCleansings[
              this.opNoteCleansings.findIndex(x => x.id === this.operative_note_wizard.cleansing_id)
            ].title)
          }
          if (this.operative_note_wizard.cleansing_2_id) {
            cleansings.push(this.opNoteCleansings[
              this.opNoteCleansings.findIndex(x => x.id === this.operative_note_wizard.cleansing_2_id)
            ].title)
          }
          const cleansing = this.$custom.toListCommaAnd(cleansings)
          operativeNoteDescription += `  ${this.$custom.randomString(
            [
              'The area was cleaned with',
              'The area was cleansed with',
              'The area was carefully cleansed with',
              'The area was cleaned using',
              'The site was carefully cleaned using',
            ],
          )} ${cleansing.toLowerCase()}.  `
        }

        // part 4
        if (this.operative_note_wizard.pain_management_id || this.operative_note_wizard.pain_management_2_id) {
          const painManagements = []
          if (this.operative_note_wizard.pain_management_id) {

            const method1Title = this.opNotePainManagements[
              this.opNotePainManagements.findIndex(x => x.id === this.operative_note_wizard.pain_management_id)
            ].title

            painManagements.push(method1Title !== 'Other' ? method1Title : this.operative_note_wizard.pain_management_other)
          }
          if (this.operative_note_wizard.pain_management_2_id) {

            const method2Title = this.opNotePainManagements[
              this.opNotePainManagements.findIndex(x => x.id === this.operative_note_wizard.pain_management_2_id)
            ].title

            painManagements.push(method2Title)
          }
          const painManagement = this.$custom.toListCommaAnd(painManagements)
          operativeNoteDescription += `  ${this.$custom.randomString(
            [
              'Pain control was achieved by',
              'Pain was managed via',
              'Pain was managed using',
              'Pain management was achieved by',
              'Pain was controlled using',
            ],
          )} ${painManagement.toLowerCase()}.  `
        }

        // part 5
        if (this.operative_note_wizard.incision_type_id) {
          const incisionType = this.opNoteIncisionTypes[
            this.opNoteIncisionTypes.findIndex(x => x.id === this.operative_note_wizard.incision_type_id)
          ].title
          let instruments = []

          if (this.operative_note_wizard.instrument_id) {
            instruments.push(this.opNoteInstruments[
              this.opNoteInstruments.findIndex(x => x.id === this.operative_note_wizard.instrument_id)
            ].title)
          }
          if (this.operative_note_wizard.instrument_2_id) {
            instruments.push(this.opNoteInstruments[
              this.opNoteInstruments.findIndex(x => x.id === this.operative_note_wizard.instrument_2_id)
            ].title)
          }
          if (this.operative_note_wizard.instrument_3_id) {
            instruments.push(this.opNoteInstruments[
              this.opNoteInstruments.findIndex(x => x.id === this.operative_note_wizard.instrument_3_id)
            ].title)
          }
          instruments = instruments.map(x => (['Surgical scissors', 'Toothed forceps', 'Pickups & scissors'].includes(x) ? x : `a ${x}`))
          const instrument = this.$custom.toListCommaAnd(instruments)
          operativeNoteDescription += `  A ${incisionType.toLowerCase()} debridement technique was ${
            this.$custom.randomString(
              [
                'employed',
                'performed',
                'conducted',
              ],
            )} using ${instrument.toLowerCase()}.  `
        }

        // part 6
        if (this.operative_note_wizard?.visualized_bone_id > 1 || this.operative_note_wizard?.visualized_muscle_id > 1) {
          const visualizedMuscleText = this.opNoteVisualizedMuscles[this.opNoteVisualizedMuscles
            .findIndex(x => x.id === this.operative_note_wizard?.visualized_muscle_id)]
            ?.title

          const visualizedBoneText = this.opNoteVisualizedBones[this.opNoteVisualizedBones
            .findIndex(x => x.id === this.operative_note_wizard?.visualized_bone_id)]
            ?.title

          if (this.operative_note_wizard?.visualized_bone_id > 1 && this.operative_note_wizard?.visualized_muscle_id > 1) {
            if (visualizedBoneText === 'Other' && visualizedMuscleText === 'Other') {
              const visualizedMuscleOtherText = this.operative_note_wizard?.visualized_muscle_other_text.toLowerCase()
              const visualizedBoneOtherText = this.operative_note_wizard?.visualized_bone_other_text.toLowerCase()

              operativeNoteDescription += `  Both ${visualizedMuscleOtherText} muscle and ${visualizedBoneOtherText} bone tissue were`
            } else {
              operativeNoteDescription += `  Both ${visualizedMuscleText.toLowerCase()} muscle and ${visualizedBoneText.toLowerCase()} bone tissue were`
            }
          } else if (this.operative_note_wizard?.visualized_muscle_id > 1) {
            if (visualizedMuscleText === 'Other') {
              const visualizedMuscleOtherText = this.operative_note_wizard?.visualized_muscle_other_text

              operativeNoteDescription += `  ${visualizedMuscleOtherText} muscle was`
            } else {
              operativeNoteDescription += `  ${visualizedMuscleText} muscle was`
            }
          } else if (this.operative_note_wizard?.visualized_bone_id > 1) {
            if (visualizedBoneText === 'Other') {
              const visualizedBoneOtherText = this.operative_note_wizard?.visualized_bone_other_text

              operativeNoteDescription += `  ${visualizedBoneOtherText} bone was`
            } else {
              operativeNoteDescription += `  ${visualizedBoneText} bone was`
            }
          }
          operativeNoteDescription += ` ${this.$custom.randomString(
            [
              'visualized',
              'observed',
              'observable',
              'perceivable',
              'visible',
              'found to be visible',
              'revealed',
            ],
          )} and excised until healthy, bleeding tissue was encountered.  `
        }

        // part 7
        if (this.operative_note_wizard.hemostasis_id || this.operative_note_wizard.hemostasis_2_id || this.operative_note_wizard.hemostasis_text) {
          const hemostasises = []
          if (this.operative_note_wizard.hemostasis_id) {
            hemostasises.push(this.opNoteHemostases[
              this.opNoteHemostases.findIndex(x => x.id === this.operative_note_wizard.hemostasis_id)
            ].title)
          }
          if (this.operative_note_wizard.hemostasis_2_id) {
            hemostasises.push(this.opNoteHemostases[
              this.opNoteHemostases.findIndex(x => x.id === this.operative_note_wizard.hemostasis_2_id)
            ].title)
          }
          if (this.operative_note_wizard.hemostasis_text && this.operative_note_wizard.hemostasis_text !== '') {
            hemostasises.push(this.operative_note_wizard.hemostasis_text)
          }
          const hemostasis = this.$custom.toListCommaAnd(hemostasises)
          operativeNoteDescription += ` ${this.$custom.randomString(
            [
              'Hemostasis',
              'Haemostasis',
            ],
          )} was ${this.$custom.randomString(
            [
              'obtained',
              'achieved',
              'managed',
              'attained',
            ],
          )} ${this.$custom.randomString(
            [
              'with',
              'via',
              'using',
            ],
          )} ${hemostasis.toLowerCase()}.  `
        }

        // part 8
        if (this.operative_note_wizard.estimated_blood_loss_id) {
          const estimatedBloodLoss = this.opNoteBloodLosses[
            this.opNoteBloodLosses.findIndex(x => x.id === this.operative_note_wizard.estimated_blood_loss_id)
          ].title
          if (estimatedBloodLoss === '0') {
            operativeNoteDescription += `  There was no${this.$custom.randomString(
              [
                ' measurable',
                '',
              ],
            )} blood loss.  `
          } else {
            operativeNoteDescription += `  ${this.$custom.randomString(
              [
                'Estimated blood loss was',
                'There was some blood loss of',
                'Blood loss was estimated to be',
                'Estimated blood loss was',
                'EBL:',
                'EBL was',
                'Blood loss:',
              ],
            )} ${estimatedBloodLoss.replace('<', 'less than ')} cc.  `
          }
        }

        // Dressings
        if ((this.woundTreatment.dressing_used_1 && this.woundTreatment.dressing_used_1 === 'None')
          || (this.woundTreatment.dressing_used_2 && this.woundTreatment.dressing_used_2 === 'None')) {
          operativeNoteDescription += 'No dressing was applied to the wound.'
        } else if (this.woundTreatment.dressing_used_1
          || this.woundTreatment.dressing_used_2
          || this.woundTreatment.dressing_used_other) {
          let dressings = [
            this.woundTreatment.dressing_used_1,
            this.woundTreatment.dressing_used_2,
            this.woundTreatment.dressing_used_other,
          ]
          dressings = this.$custom.removeNoneOther(dressings)
          const dressing = this.$custom.toListCommaAnd(dressings, null, 'none')
          operativeNoteDescription += `  ${dressing} ${
            (dressings.length > 1 ? 'were' : 'was')
          } applied to the wound.  `
        }

        this.operative_note = this.$custom.clean(operativeNoteDescription, 'Operative Note Description')
        this.$emit('update:operative-note-wizard', this.operative_note_wizard)
      }
    },
  },
  mounted() {
  },
  methods: {
    fieldContains(field, contains) {
      return !field ? false : field.toLowerCase().includes(contains)
    },
  },
}
</script>

<style lang="scss">
.operative-note-history {
  width: 100%;
  font-size: 15px;
  max-height: 500px;
  border: thin solid rgba(0, 0, 0, 0.14);
  overflow-y: auto;
  .row {
    border: thin solid rgba(0, 0, 0, 0.14);
    border-top-width: 0px;
    margin: 0 -4px;
    &:first-child {
      border-top-width: thin;
    }
    label {
      background-color: map-get($shades, 'white');
      color: var(--v-secondary-darken1);
      white-space: nowrap;
      border-right: thin solid rgba(0, 0, 0, 0.14);
    }
    > div {
      background-color: map-get($shades, 'white');
      color: var(--v-secondary-darken3);
    }
  }
}
</style>
