<template>
  <NoteSection
    label="COVID-19 Assessment"
  >
    <radio-buttons
      v-model="covid.have_cough"
      :items="query"
      label="Does the patient presently have a cough?"
      class="col-sm-12"
      clearable
      row
    ></radio-buttons>
    <radio-buttons
      v-model="covid.have_fever"
      :items="query"
      label="Does the patient presently have a fever?"
      class="col-sm-12"
      clearable
      row
    ></radio-buttons>
    <radio-buttons
      v-model="covid.have_weakness"
      :items="query"
      label="Does the patient presently have weakness or lethargy?"
      class="col-sm-12"
      clearable
      row
    ></radio-buttons>
    <date-picker
      v-model="covid.covid_test_date"
      :max="$date().format('YYYY-MM-DD')"
      label="Formal COVID-19 Test Date"
      class="col-sm-12"
      clearable
      @input="visitDateCheck"
    ></date-picker>
    <select-box
      v-if="isCovidTested"
      v-model="covid.test_result"
      :items="testResults"
      label="COVID-19 Test Result"
      class="col-sm-6"
      required
    >
    </select-box>
    <select-box
      v-if="isCovidTested"
      v-model="covid.test_type"
      :items="testTypes"
      label="COVID-19 Test Type"
      class="col-sm-6"
      required
    >
    </select-box>
    <text-field
      v-if="isCovidTested && isCovidTestTypeOther"
      v-model="covid.other_type"
      class="col-sm-12"
      label="Enter other COVID-19 test type..."
      counter="50"
      required
    ></text-field>
    <alert
      v-if="covidWarning"
      class="col-sm-12"
    >
      Patient may have possible COVID infection.
    </alert>
  </NoteSection>
</template>

<script>
import { mapGetters } from 'vuex'

const initialData = {
  have_cough: null,
  have_fever: null,
  have_weakness: null,
  covid_test_date: null,
  covid_test_date_us: null,
  test_result: null,
  test_type: null,
  other_type: null,
}

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    encounterVisitDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      covid: {
        ...initialData,
        ...this.value,
      },
      encounter_visit_date: this.encounterVisitDate,
    }
  },
  computed: {
    ...mapGetters('baseData', ['query']),
    ...mapGetters('encounters', ['testResults', 'testTypes']),
    covidWarning() {
      return this.covid.have_cough
        || this.covid.have_fever
        || this.covid.have_weakness
        || (
          this.covid.test_result === 'Positive'
          && this.$date().diff(this.covid.covid_test_date, 'day') <= 20
        )
    },
    isCovidTested() {
      return !!this.covid.covid_test_date
    },
    isCovidTestTypeOther() {
      return this.covid.test_type === 'Other'
    },
  },
  watch: {
    'covid.covid_test_date': {
      handler(newValue, oldValue) {
        if (newValue === null) {
          this.covid.test_result = null
          this.covid.test_type = null
          this.covid.other_type = null
        }
        this.covid.covid_test_date_us = newValue !== null
          ? this.$date(newValue).format('MM/DD/YYYY')
          : null
        if (newValue !== oldValue && newValue) this.dateSpanCheck(newValue)
      },
    },
    covid: {
      deep: true,
      handler() {
        this.$emit('input', this.covid)
      },
    },
  },
  mounted() {
    // method from encounter details to update variable when date changes
    this.$root.$on('dateChange', date => {
      this.encounter_visit_date = date
      if (this.covid.covid_test_date) this.visitDateCheck()
    })
  },
  methods: {
    // shows dialog if visit date occurs before selected formal test date
    visitDateCheck() {
      if (this.encounter_visit_date < this.covid.covid_test_date) {
        this.$root.confirm({
          title: 'Notice: Date of Service',
          titleIconColor: 'warning',
          body: 'Covid test date cannot occur after the note date of service',
          cancel: false,
        })
        this.covid.covid_test_date = undefined
      }
    },
    dateSpanCheck(date) {
      // if visit is greater than 60 days notify
      const visitDate = new Date(date)
      visitDate.setDate(visitDate.getDate() + 60)
      const [finalDate] = visitDate.toISOString().split('T')

      if (finalDate < this.encounter_visit_date) this.$store.dispatch('notify', { value: 'Dates spanning 8 or more weeks from the DOS may be invalid', color: 'warning' })
    },
  },
}
</script>
