<template>
  <NoteSection
    label="Advance Care Planning"
  >
    <help-icon
      title="Advance Care Planning"
      body="<p>Discuss &amp; document whether an Advance Care Plan exists in the patient's medical record.</p>
      <p><b>YES</b> = patient has an Advance Care Plan documented in the medical record.</p>
      <p><b>NO</b> = patient does not have an Advance Care Plan and is not able to/does not wish to provide one.</p>"
    ></help-icon>
    <radio-buttons
      v-model="advance_care_plan"
      :items="query"
      label="Patient has Advance Care Plan?"
      class="col-sm-12"
      required
      row
    ></radio-buttons>
  </NoteSection>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      advance_care_plan: this.value,
    }
  },
  computed: {
    ...mapGetters('baseData', ['query']),
  },
  watch: {
    advance_care_plan() {
      this.$emit('input', this.advance_care_plan)
    },
  },
  methods: {
  },
}
</script>
